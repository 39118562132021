import React, { PureComponent } from 'react';

class ConfigAddFormContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            key: "",
            value: "",
        }
    }

    onSubmit = async (e) => {
        e.preventDefault();
        this.props.closeModal();
        await this.props.saveSetting({ key: this.state.key, value: this.state.value })
        this.setState({
            loading: false,
        })
    }

    handleKeyChange = (e) => {
        this.setState({ key: e.target.value });
    }
    handlevValueChange = (e) => {
        this.setState({ value: e.target.value });
    }

    render() {
        const { key, value } = this.state
        return (
            <>
                <form>
                    <div className="form-group">
                        <label htmlFor="key">Key</label>
                        <input
                            type="email"
                            className="form-control"
                            id="key"
                            placeholder="setting key"
                            value={key}
                            onChange={this.handleKeyChange} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="value">Value</label>
                        <input
                            type="email"
                            className="form-control"
                            id="value"
                            placeholder="setting value"
                            value={value}
                            onChange={this.handlevValueChange} />
                    </div>
                    <button
                        type="submit"
                        className="btn btn-danger btn-lg float-right"
                        disabled={key === '' || value === ''}
                        onClick={this.onSubmit}>Save</button>
                </form>
            </>
        );
    }
}

export default ConfigAddFormContainer;
