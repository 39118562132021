import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Api from '../../api/core/base';
import { LinkButton, Loading } from '../../components';
import { SwalAlert } from '../../helpers';
class SubscriptionViewContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subscription: {},
            loading: true
        }
    }

    componentDidMount = async () => {
        await this.fetchData()
    }

    fetchData = async () => {
        try {
            const { id } = this.props.match.params;
            const res = await Api.get(`/subscription/${id}`, {});
            if (!res || res.status !== 200) {
                throw new Error()
            }
            this.setState({
                subscription: res.data,
                loading: false
            })
        } catch (error) {
            await SwalAlert.error('Get data Failed', async () => {
                this.setState({ loading: false })
            });
        }
    }

    formatMoney = (amount) => {
        return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'USD' }).format(amount / 100)
    }

    render() {
        const { loading, subscription } = this.state
        const { practitioner, plan } = subscription
        const tax_percent = Math.round(subscription.amount_tax / subscription.amount_untaxed * 100)
        const { formatMoney } = this
        return (
            <>
                <div className="card-header py-3 d-flex justify-content-between align-items-center">
                    <h6 className="m-0 font-weight-bold text-primary">Details Practitioner Subscriptions </h6>
                    <div className="buttons">
                        <LinkButton href={'/subscriptions'}>Back</LinkButton>
                    </div>
                </div>
                {
                    loading ? <Loading /> : (
                        <div className="card-body">
                            <table className="table table-bordered table-hover mt-5">
                                <thead>
                                    <tr className="table-active">
                                        <th colSpan="4">Technical Information</th>
                                    </tr>
                                    <tr>
                                        <th>ID</th>
                                        <td>{subscription.id}</td>
                                        <th>Practitioner</th>
                                        <td>{`${practitioner.firstname} ${practitioner.lastname}`}</td>
                                    </tr>
                                    <tr>
                                        <th>Stripe Subscription ID</th>
                                        <td>{subscription.s_subscription_id}</td>
                                        <th>Freemium Subscription</th>
                                        <td>
                                            <input type="checkbox" disabled={true} checked={subscription.freemium_subscription} />
                                        </td>
                                    </tr>
                                    {/* <td>
                                        <input type="checkbox" disabled={true} />
                                    </td> */}
                                    <tr>
                                        <th>Current Period Start</th>
                                        <td>{subscription.current_period_start}</td>
                                        <th>Current Period End</th>
                                        <td>{subscription.current_period_end}</td>
                                    </tr>
                                    <tr>
                                        <th>Date Order</th>
                                        <td>{subscription.date_order}</td>
                                        <th>trial_end</th>
                                        <td>{subscription.trial_end}</td>
                                    </tr>
                                    <tr>
                                        <th>Cancel At Period End</th>
                                        <td>
                                            <input type="checkbox" disabled={true} checked={subscription.cancel_at_period_end} />
                                        </td>
                                        <th>Trial From Plan</th>
                                        <td>
                                            <input type="checkbox" disabled={true} checked={subscription.trial_from_plan} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Expiration Date</th>
                                        <td>{subscription.validity_date}</td>
                                        <th>Confirmation Date</th>
                                        <td>{subscription.confirmation_date}</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="table-active">
                                        <th colSpan="4">Plan Info</th>
                                    </tr>
                                    <tr>
                                        <th>Product</th>
                                        <td>{plan.nickname}</td>
                                        <th>Interval</th>
                                        <td>{`${plan.interval_count} (${plan.interval})`}</td>
                                    </tr>
                                    <tr>
                                        <th>Amount</th>
                                        <td>{plan.amount}</td>
                                        <th>Tax</th>
                                        <td>
                                            <span className="badge badge-dark">{`Plan Tax (${tax_percent}%)`}</span>
                                        </td>
                                    </tr>
                                    <tr className="table-active">
                                        <th colSpan="4">Other Info</th>
                                    </tr>
                                    <tr>
                                        <th>send_remind_24h_trial</th>
                                        <td>
                                            <input type="checkbox" disabled={true} checked={subscription.send_remind_24h_trial} />
                                        </td>
                                        <th>send_remind_7d_trial</th>
                                        <td>
                                            <input type="checkbox" disabled={true} checked={subscription.send_remind_7d_trial} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="4" className="text-right">
                                            <h6 className="font-weight-bold">Untaxed Amount: <small className='ml-3'>{formatMoney(subscription.amount_untaxed)}</small></h6>
                                            <h6 className="font-weight-bold">Taxes: <small className='ml-3'>{formatMoney(subscription.amount_tax)}</small></h6>
                                            <hr className="w-50" style={{ marginRight: 'initial' }} />
                                            <h5 className="font-weight-bold">Total : <small className='ml-3 font-weight-bold'>{formatMoney(subscription.amount_total)}</small></h5>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    )
                }
            </>
        );
    }
}

export default withRouter(SubscriptionViewContainer);
