import Api from '../../api/core/base'
import {useState, useEffect} from 'react';
import Swal from "../../helpers/Swal";
export default function SettingContainer(props){

    const [state, setState] = useState({
        userName: '',
        password: '',
        newPassword: '',
        userInfo: {}
    });

    useEffect(async () => {
        await getDataInfo()
    }, [])



    async function getDataInfo() {
        const userInfo = JSON.parse(localStorage.getItem("ca-app-admin-info"));
        const res = await Api.get("/api/admin/info");
        if (res.data) {
            setState({...state,userName: res.data.admin.username, password: res.data.admin.password, userInfo})
        }
    }
    const onInputUserName = (event) => {
        const val = event.target.value;
        setState({...state,userName: val.trim()})

    }
    const onInputPassword = (event) => {
        const val = event.target.value;
        setState({...state,password: val.trim()})
    }
    const onInputNewPassword = (event) => {
        const val = event.target.value;
        setState({...state,newPassword: val.trim()})
    }
    function saveData() {
        const {password,newPassword, userName, userInfo} = state;
        if (!newPassword || !password || !userName) {
            return Swal.error("Please fill in all fields in form");
        }
        Api.post("/api/admin/change-password", {
            password,
            data: {
                password:newPassword,
                username: userName,
                role: userInfo.role
            }
        }).then((res) => {
            if(res.errors) {
                return Swal.error('Old password is not valid')
            }
            return Swal.success('Change password successfully', () => {
                localStorage.removeItem("ca-app-admin-token")
                sessionStorage.removeItem("ca-app-admin-token")
                window.location.href = "/login"
            })
        }).catch((err) => {
            return Swal.error('Internal server error')
        } )
    }

    return (
        <div id="wrapper">
            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col">
                                <div className="card shadow mb-4">
                                    <div className="card-header py-3 d-flex justify-content-between align-items-center">
                                        <h6 className="m-0 font-weight-bold text-primary">Setting</h6>
                                        <button className="btn btn-primary" onClick={() => {saveData()}}>Save</button>
                                    </div>
                                    <div className="card-body " id="partner">
                                        <form id="change-password-form">
                                            <input type="text" id="username" className="mt-3 fadeIn second" name="username"
                                                   placeholder="Username" value={state.userName} onInput={(e) => {onInputUserName(e)}}/>
                                                <input type="password" id="password" className="mt-3 fadeIn third" name="password"
                                                       placeholder="Current Password" value={state.password} onInput={(e) => {onInputPassword(e)}}/>
                                                    <input type="password" id="new-password" className="mt-3 fadeIn third"
                                                           name="new-password" placeholder="New password" value={state.newPassword} onInput={(e) => {onInputNewPassword(e)}}/>
                                                        <p className="error"/>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
}