import React, {useState} from "react";
import  Api from '../../api/core/base';
import {useHistory} from "react-router-dom";

function changeFormatToArray(value) {
    value = value + ',';
    value = value.replace(" ", "");
    value = value.split(',')
    value.length -= 1
    return value
}
export default function EditPartnerModal(props) {
    const {partner, closeModal} = props;
    const history = useHistory();
    const {name, allow_urls, whitelist_ips} = partner;
    const [userName, setUserName] = useState(name);
    const [whiteListIps,setWhiteListIps] = useState(whitelist_ips);
    const [allowUrls, setAllowUrls] = useState(allow_urls);
    const [isNewSecretKeyGenerated, setIsNewSecretKeyGenerated] = useState(false);

    const [errorField, setErrorField] = useState('');
    const onEditPartner = (e) => {
        e.preventDefault();
        if(!userName) {
            setErrorField("userName");
        }
        const modifiedAllowUrls = changeFormatToArray(allowUrls);
        const modifiedWhiteListIps = changeFormatToArray(whiteListIps);
        if (!isValidUrls(modifiedAllowUrls)) {
            alert('Please input valid urls');
            return;
        }
        if (!isValidWhiteListIps(modifiedWhiteListIps)) {
            alert('Please input valid white list ips');
            return;
        }
        Api.editPartner({
            generate_secret_key: isNewSecretKeyGenerated,
            id: partner.id,
            name: userName,
            allow_urls: allowUrls,
            whitelist_ips: whiteListIps
        }).then(() => {
            history.go(0);
        })

    }
    const onInputWhiteListIps = (e) => {
        setWhiteListIps(e.target.value ? changeFormatToArray(e.target.value) : e.target.value);
    }
    const onInputUserName = (e) => {
        const newName = e.target.value;
        setUserName(newName);
    }
    const onCheckNewSecretKey = (e) => {
        const isChecked = e.target.checked;
        setIsNewSecretKeyGenerated(isChecked);
    }
    const onInputUrls = (e) => {
        setAllowUrls(e.target.value ? changeFormatToArray(e.target.value) : e.target.value);
    }
    const isValidUrls = (allowUrls) => {
        console.log('Url', allowUrls);
        let isValid = true;
        const urlPattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i');
        if (allowUrls) {
            for (let i=0; i<allowUrls.length; i++) {
                if (allowUrls[i] !== '' && !urlPattern.test(allowUrls[i])) {
                    isValid = false
                    break
                }
            }
        }
        return isValid;
    }

    const isValidWhiteListIps = (whiteListIps) => {
        console.log('White list', whiteListIps);
        let isValid = true;
        const ipsPattern = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
        if (whiteListIps) {
            for (let i=0; i<whiteListIps.length; i++) {
                if (whiteListIps[i] !== '' && !ipsPattern.test(whiteListIps[i])) {
                    isValid = false
                    break
                }
            }
        }
        return isValid;
    }
    return (
        <form id="partner-form">
            <div className="row">
                <div className="col-md-12">
                    <label>Name:</label>
                    <input type="text" placeholder="Partner name" value={userName ? userName : ""
                    } name="userName" onInput={(e) => {onInputUserName(e)}}/>
                </div>
                <div className="col-md-12">
                    <label>Allow URLs:</label>
                    <input type="text" placeholder="https://abc.com, https://cde.com" value={allowUrls}
                     name="allow_urls" onInput={(e) => {onInputUrls(e)}}/>
                </div>
                <div className="col-md-12">
                    <label >White list:</label>
                    <input type="text" placeholder="1.6.8.0, 1.2.3.4" value={whiteListIps}
                    name="whitelist_ips" onInput={(e) => {onInputWhiteListIps(e)}} />
                </div>
                <div className="col-md-12">
                    <div className="custom-control custom-checkbox mt-3">
                        <input type="checkbox"  name="generate_secret_key" className="custom-control-input" onChange={(e) => {onCheckNewSecretKey(e)}} id="generate_secret_key"/>
                        <label className="custom-control-label" >Generate new Secret Key</label>
                    </div>
                </div>
                <div className="col-md-12 buttons d-flex justify-content-between mb-4 pt-4">
                    <button className="btn btn-primary" onClick={(e) => {onEditPartner(e)}}>Submit</button>
                    <button className="btn btn-secondary" onClick={(e) => {closeModal()}}>Close</button>
                </div>
            </div>
        </form>)
}