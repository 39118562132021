import Api from './core/base';
const LOGIN = '/api/admin/login';

const Auth = {
  login: async (params = {}) => {
    return await Api.post(LOGIN, params)
  }
}

export default Auth



