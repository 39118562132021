import moment from 'moment';
import React, { Component } from 'react';
import { useHistory } from 'react-router-dom'

const STATUS_COLOR = {
    'paid': 'badge-success',
    'cancel': 'badge-danger',
}


const displayColumns = [
    {
        name: 'id',
        displayName: 'id'
    },
    {
        name: 'date_order',
        displayName: 'Order Date',
    },
    {
        name: 'current_period_start',
        displayName: 'Current Period Start',
    },
    {
        name: 'current_period_end',
        displayName: 'Current Period End',
    },
    {
        name: 'practitioner',
        displayName: 'practitioner',
    },
    {
        name: 'amount_total',
        displayName: 'Total',
    },
    {
        name: 'state',
        displayName: 'Status',
    },
];

const SubscriptionTableView = (props) => {

    const formatMoney = (amount) => {
        return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'USD' }).format(amount / 100)
    }
    const format_data = (name, value) => {
        let format_value
        switch (name) {
            case 'current_period_start':
            case 'current_period_end':
            case 'date_order':
                format_value = moment.unix(value).format("DD/MM/YYYY HH:MM:SS");
                break;
            case 'practitioner':
                format_value = `${value.firstname || ''} ${value.lastname || ''}`
                break;

            case 'state':
                format_value = <span className={`badge  ${STATUS_COLOR[value]} text-white`}>{value}</span>
                break;
            case 'amount_total':
                format_value = formatMoney(value);
                break;
            default:
                format_value = value
                break;
        }

        return format_value
    }
    const { subscriptions } = props
    const history = useHistory();

    const onRowClick = (id) => {
        history.push(`/subscriptions/${id}`);
    }

    return (
        <>
            {
                subscriptions && subscriptions.length > 0 ? (
                    <table className="table table-bordered table-hover mt-5">
                        <thead>
                            <tr>
                                {displayColumns.map((c, h_idx) => (
                                    <th
                                        scope="col"
                                        key={h_idx}
                                    >
                                        {c.displayName}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {subscriptions.map((r, r_idx) => (
                                <tr
                                    key={r_idx}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        onRowClick(r['id'])
                                    }}>
                                    {displayColumns
                                        .map((c, c_idx) => (
                                            <td key={c_idx}>
                                                {format_data(c.name, r[c.name])}
                                            </td>
                                        ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <div className="p-3 text-center">
                        <span>No Data</span>
                    </div>
                )

            }
        </>
    );
}

export default SubscriptionTableView;
