import { yupResolver } from '@hookform/resolvers/yup';
import { noop } from 'jquery';
import React from 'react';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import * as yup from 'yup';

let editValidatedSchema = yup.object({
    username: yup.string().required('User Name required'),
    role: yup.string().required('Role required'),
});

let addValidatedSchema = yup.object({
    username: yup.string().required('User Name required'),
    role: yup.string().required('Role required'),
    password: yup.string().required('Password required').test('len', 'Must be at least 6 characters', val => val.length >= 6),
});

const AdminAccountDialogContainer = (props) => {
    const { onSave, type, account } = props;
    const validatedSchema = type === 'edit' ? editValidatedSchema : addValidatedSchema
    const { register, handleSubmit, errors, trigger } = useForm({
        resolver: yupResolver(validatedSchema),
    });

    const onSubmit = async (data, e) => {
        e.preventDefault();
        await onSave({
            id: account.id || null,
            ...data
        })
    };
    return (
        <form className="form-flex" onSubmit={handleSubmit(onSubmit)}>
            <div className="row mb-2">
                <div className="col-md-12">
                    <label htmlFor="username">Username:</label>
                    <input
                        type="text"
                        placeholder="User name"
                        name="username"
                        id="username"
                        defaultValue={account.username}
                        autoFocus={true}
                        ref={register} />
                    {errors.username && (
                        <div className="form-error error mb-1 ml-2" role="alert">
                            {errors.username.message}
                        </div>
                    )}
                </div>
                <div className="col-md-12">
                    <label htmlFor="name">Role:</label>
                    <select ref={register} defaultValue={account.role} name="role">
                        <option value="admin">Admin</option>
                        <option value="sys_admin">System Admin</option>
                    </select>
                </div>
                {
                    type === 'add' && (
                        <div className="col-md-12">
                            <label htmlFor="password">Password:</label>
                            <input
                                type="text"
                                placeholder="Password"
                                name="password"
                                id="password"
                                autoFocus={true}
                                ref={register} />
                            {errors.password && (
                                <div className="form-error error mb-1 ml-2" role="alert">
                                    {errors.password.message}
                                </div>
                            )}
                        </div>
                    )
                }
            </div>

            <button
                type="submit"
                onClick={() => {
                    trigger();
                }}
                title="Save"
                name="save"
                className="btn btn-danger btn-lg float-right">Save</button>
        </form>
    );
}

AdminAccountDialogContainer.defaultProps = {
    onSave: noop,
    type: 'edit',
    account: {}
}

export default AdminAccountDialogContainer;
