import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import SubscriptionListContainer from './SubscriptionListContainer'
import SubscriptionViewContainer from './SubscriptionViewContainer';
class SubscriptionHomeContainer extends Component {

    render() {
        return (
            <div className="card shadow mb-4">
                <Switch>
                    <Route path="/subscriptions/:id">
                        <SubscriptionViewContainer {...this.props} />
                    </Route >
                    <Route path="/subscriptions">
                        <SubscriptionListContainer {...this.props} />
                    </Route>
                </Switch>
            </div>
        );
    }
}

export default SubscriptionHomeContainer;
