import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import * as yup from 'yup';
import { FEATURE_LIST_PRO_PLAN } from '../../constants';
import { SwalAlert } from '../../helpers';

const changePasswordSchema = yup.object({
    nickname: yup.string().required('Product Name is required'),
    amount: yup.string().required('Price required'),
    interval_count: yup.string().required('interval_count required'),
});

const PlanViewContainer = (props) => {
    const { onAddNewPlan, featureList } = props;
    console.log('featureList : ', featureList)
    const [tab, setTab] = useState('info')
    const [selectedFeatures, setSelectedFeatures] = useState([])
    const [featuresAddList, setFeaturesAddList] = useState([])
    const [isAllowTrialChecked, setAllowTrialCheck] = useState(false)
    const { register, handleSubmit, errors, trigger } = useForm({
        resolver: yupResolver(changePasswordSchema),
    });

    const onSubmit = async (data, e) => {
        e.preventDefault();
        if (data.isFree) {
            SwalAlert.error(`Can only have 1 Free Plan`, () => {
            });
        } else {
            let newfeatures = featuresAddList
            FEATURE_LIST_PRO_PLAN.map((feature) => {
                if (!newfeatures.includes(feature)) {
                    newfeatures.push(feature)
                }
            })
            const newPlan = {
                ...data,
                i18n_name: data.nickname.trim().split(" ").join("_"),
                features: newfeatures
            }

            await onAddNewPlan(newPlan)
        }
    };

    const handleOnChange = (e) => {
        let value = Array.from(e.target.selectedOptions, option => option.value);
        setSelectedFeatures(value)
    }

    const addFeatureToList = () => {
        selectedFeatures.forEach(f => {
            featuresAddList.push(f)
        });
        setFeaturesAddList(featuresAddList)
        setSelectedFeatures([])
    }

    const removeFeatureFromList = (value) => {
        const index = featuresAddList.indexOf(value)
        let newList = featuresAddList.slice()
        newList.splice(index, 1);
        setFeaturesAddList(newList)
    }

    return (
        <form className="form-flex" onSubmit={handleSubmit(onSubmit)}>
            <div className="card-body">
                <div className="d-flex justify-content-between">
                    <label htmlFor="product_name">Product Name</label>
                    <button
                        className="btn btn-danger"
                        onClick={() => {
                            setTab('info')
                            trigger();
                        }}
                        title="Save"
                        type="submit"
                        name="save">
                        Add new Plan
                    </button>
                </div>
                <input
                    type="text"
                    className="text-center w-50"
                    placeholder="Product Name"
                    name="nickname"
                    id="nickname"
                    autoFocus={true}
                    ref={register}
                />
                {errors.nickname && (
                    <div className="form-error error mb-1 ml-2" role="alert">
                        {errors.nickname.message}
                    </div>
                )}
                <label className="text-primary font-weight-bold"><input style={{ maxWidth: '20px' }} type="checkbox" disabled={true} checked={true} className="mr-2" />Can be Sold</label>
                <label className="text-primary font-weight-bold"><input style={{ maxWidth: '20px' }} type="checkbox" disabled={true} checked={true} className="mr-2" />Can be Purchased</label>
                <label className="text-primary font-weight-bold"><input style={{ maxWidth: '20px' }} type="checkbox" disabled={true} checked={true} className="mr-2" />Can be Subscribed</label>
                <nav className="navbar navbar-expand-sm bg-dark navbar-dark mt-4">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className={`nav-item nav-link ${tab === 'info' ? 'active' : ''}`} style={{ cursor: 'pointer' }} onClick={() => { setTab('info') }}
                            >General Informations<span className="sr-only">(current)</span></a>
                        </li>
                        <li className="nav-item">
                            <a className={`nav-item nav-link ${tab === 'feature' ? 'active' : ''}`} style={{ cursor: 'pointer' }} onClick={() => { setTab('feature') }}>
                                Features<span className="sr-only">(current)</span></a>
                        </li>
                    </ul>
                </nav>
                {
                    tab === 'info' ? (
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <td style={{ verticalAlign: 'middle' }}>Service</td>
                                    <th>List Price</th>
                                    <td>
                                        <input
                                            type="number"
                                            step="0.001"
                                            className="p-0 text-center"
                                            defaultValue={0}
                                            min={0}
                                            name="amount"
                                            id="amount"
                                            ref={register}
                                        />
                                        {errors.amount && (
                                            <div className="form-error error mb-1 ml-2" role="alert">
                                                {errors.amount.message}
                                            </div>
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Welcome Plan</th>
                                    <td>
                                        <input className="col-1" type="checkbox" disabled={true} />
                                    </td>
                                    <th>Free Plan</th>
                                    <td>
                                        <input
                                            className="col-1"
                                            type="checkbox"
                                            onChange={(e) => {
                                                // setAllowTrialCheck(e.target.checked)
                                            }}
                                            ref={register}
                                            name="isFree"
                                            id="isFree" />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Allow Trial</th>
                                    <td style={{ verticalAlign: 'middle' }}>
                                        <input
                                            type="checkbox"
                                            className="col-1"
                                            onChange={(e) => {
                                                setAllowTrialCheck(e.target.checked)
                                            }}
                                            ref={register}
                                            name="isAllowTrial"
                                            id="isAllowTrial" />
                                    </td>
                                    <th>Trial Period Days</th>
                                    <td>
                                        {
                                            (isAllowTrialChecked) ? (
                                                <input
                                                    type="number"
                                                    className="p-0 text-center"
                                                    defaultValue={1}
                                                    min={1}
                                                    name="trial_period_days"
                                                    id="trial_period_days"
                                                    ref={register}
                                                />
                                            ) : ''
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <th style={{ verticalAlign: 'middle' }}>Billing Interval</th>
                                    <td>
                                        <select
                                            className="form-select"
                                            ref={register}
                                            // onChange={handleSubmit(onSubmit)}
                                            aria-label="Interval"
                                            name="interval"
                                            id="interval"
                                            defaultValue='month'
                                        >
                                            <option value="day">Day</option>
                                            <option value="week">Week</option>
                                            <option value="month">Month</option>
                                            <option value="year">Year</option>
                                        </select>
                                    </td>
                                    <th style={{ verticalAlign: 'middle' }}>Billing Interval Count</th>
                                    <td style={{ verticalAlign: 'middle' }}>
                                        <input
                                            type="number"
                                            className="p-0 text-center"
                                            defaultValue={1}
                                            min={1}
                                            name="interval_count"
                                            id="interval_count"
                                            ref={register}
                                        />
                                        {errors.interval_count && (
                                            <div className="form-error error mb-1 ml-2" role="alert">
                                                {errors.interval_count.message}
                                            </div>
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Inherit Plan</th>
                                    <td></td>
                                    <th>Base Plan</th>
                                    <td>
                                        <input className="col-1" type="checkbox" disabled={true} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Stripe Product ID</th>
                                    <td></td>
                                    <th>Stripe Plan ID</th>
                                    <td></td>
                                </tr>
                            </thead>

                        </table>
                    ) : (
                            <>
                                <div className="mt-2 mb-2">
                                    <div className="d-flex justify-content-between mb-2">
                                        <p>Add new Feature</p>
                                        <button
                                            className={'btn btn-danger ml-1'}
                                            title="Add"
                                            disabled={selectedFeatures.length <= 0}
                                            onClick={addFeatureToList}
                                            name="add"
                                        >
                                            Add
                                        </button>
                                    </div>
                                    <select className="form-select" onChange={handleOnChange} multiple aria-label="multiple select example" size="5">
                                        {
                                            featureList.filter(f => !featuresAddList.includes(f))
                                                .map((feature, index) => <option key={index} value={feature}>{feature}</option>)
                                        }
                                    </select>
                                </div>
                                <p>Feature Lists</p>
                                <ul
                                    className="list-group"
                                    ref={register}
                                    name="feature_list"
                                    id="feature_list">
                                    {
                                        featuresAddList.map((feature, index) => (
                                            <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                                                {feature}
                                                <span
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => { removeFeatureFromList(feature) }}>
                                                    <i className="fas fa-trash-alt"></i>
                                                </span>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </>
                        )
                }
            </div>
        </form>
    );
}

export default withRouter(PlanViewContainer);
