import React, { Component } from 'react';

class Topbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userInfo: {}
        }
    }
    componentDidMount() {
        const userInfo = JSON.parse(localStorage.getItem("ca-app-admin-info"));
        this.setState({
            userInfo
        })
    }


    logOut = () => {
        localStorage.removeItem("ca-app-admin-token");
        localStorage.removeItem("ca-app-admin-info");
        sessionStorage.removeItem("ca-app-admin-token");
        window.location.href = `${process.env.REACT_APP_BASENAME === '/' ? '' : process.env.REACT_APP_BASENAME}/login`
    }

    render() {
        return (
            <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
                <ul className="navbar-nav ml-auto">
                    <div className="topbar-divider d-none d-sm-block"></div>
                    <li className="nav-item dropdown no-arrow">
                        <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                            <span id="navbar_username" className="mr-2 d-none d-lg-inline text-gray-600 small">{this.state.userInfo?.userName} </span>
                        </a>
                        <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                            aria-labelledby="userDropdown">

                            <a onClick={() => { this.logOut() }} className="dropdown-item" href="#" data-toggle="modal"
                                data-target="#logoutModal">
                                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                Logout
                            </a>
                        </div>
                    </li>
                </ul>
            </nav>
        );
    }
}

export default Topbar;
