const axios = require('axios').default;

// Set config defaults when creating the instance
const api = axios.create({
  baseURL:
    process.env.REACT_APP_API_ENDPOINT ||
    `${window.location.protocol}://${window.location.host}`,
});
api.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT || '/';
// api.defaults.crossdomain = true;
api.defaults.headers.common['Authorization'] = localStorage.getItem(
  'ca-app-admin-token',
) || sessionStorage.getItem("ca-app-admin-token");
api.defaults.headers.post['Content-Type'] = 'application/json';





export default api;
