import React, { Component } from 'react';

class Loading extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="d-flex justify-content-center mt-4 pt-4 mb-4 pb-4">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Loading;
