import React, { Component, PureComponent } from 'react';
import { debounceTime, startWith } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
// utils
import { PAGE_SIZE_DEFAULT } from '../../constants';
import { SwalAlert, buildFilterParams } from '../../helpers';
// Route
import { Switch, Route } from 'react-router-dom';
// components
import { TablePagination, Loading } from '../../components';
import PractitionerTableView from './PractitionerTableView';
import PractititionerToolbar from './PractitionerToolbar';
import PractitionerEditContainer from './PractitionerEditContainer';
import  Api  from '../../api/core/base';

class PractitionerListContainer extends PureComponent {
  constructor(props) {
    super(props);
    // state
    this.state = {
      loading: true,
      practitioners: [],
      page: null,
      pageIndex: 1,
      pageSize: PAGE_SIZE_DEFAULT,
      filter: {},
    };
  }

  archivePractitioner = async (id) => {
    console.error('Archive this practitioner ' + id);
    // await api.archivePractitioner(`/practitioner/${id}`, {
    //   id,
    //   status: 'inactive',
    // });
    await Api.archivePractitioner(id)
    this.querySubject.next({
      t: Date.now(),
    });
    SwalAlert.success('Archived this practitioner ' + id + ' successfully!');
  };

  onDelete = (evt, id) => {
    evt.preventDefault();
    SwalAlert.confirm(
      'Are you sure to archive this practitioner?',
      async () => {
        await this.archivePractitioner(id);
      },
    );
  };

  onFilterChanges = (filter) => {
    this.filterSubject.next(filter);
  };

  onPageChange = (pageIndex) => {
    this.querySubject.next({ pageIndex });
  };

  onPageSizeChange = (pageSize) => {
    this.querySubject.next({ pageSize: pageSize });
  };

  async fetchPractitioner({ filter, pageIndex, pageSize, sort }) {
    try {
      const params = buildFilterParams(filter, { pageIndex, pageSize }, sort);
      const res = await Api.get('/practitioners', { params });
      return {
        page: res.data && res.data.page ? res.data.page : null,
        practitioners:
          res.data && res.data.practitioners ? res.data.practitioners : [],
      };
    } catch (error) {
      console.error('Failed', error);
    }
  }

  filterBuild = (filter) => {
    // eg
    // const filter = {
    //   or: {
    //     firstname: 'snm',
    //     lastname: 'snm',
    //     email: 'snm'
    //   },
    //   and: {
    //     app_id: 'normal',
    //   },
    // }
    const filterParams = {};
    if (!filter) return filterParams;
    const { s, app_id, status } = filter;
    if (s && s.length > 0) {
      // email,firstname,lastname
      filterParams['or'] = {
        email: s,
        firstname: s,
        lastname: s,
      };
    }
    if (status) {
      filterParams['and'] = {
        status,
      };
    }
    return filterParams;
  };

  async componentDidMount() {
    // request API
    try {
      this.setState({ loading: true });
      // setup

      this.filterSubject = new BehaviorSubject(null);
      this.querySubject = new BehaviorSubject(null);

      this.filterObs = this.filterSubject
        .asObservable()
        .pipe(debounceTime(500))
        .subscribe((filter) => {
          if (filter) {
            this.setState({ filter });
            this.querySubject.next({ filter });
          }
        });

      this.queryObs = this.querySubject
        .asObservable()
        .pipe(
          startWith({ filter: {}, pageIndex: 1, pageSize: PAGE_SIZE_DEFAULT }),
        )
        .subscribe(async (query) => {
          if (query) {
            // update pageSize or filter
            if (query.pageSize || query.filter) {
              // reset page index
              query.pageIndex = 1;
            } else {
              // default
              query.pageIndex = query.pageIndex || this.state.pageIndex;
            }
            query.filter = query.filter
              ? this.state.filter
              : { ...this.state.filter, ...query.filter };
            query.pageSize = query.pageSize || this.state.pageSize;

            // update state before calling API
            this.setState({
              loading: true,
              filter: query.filter,
              pageSize: query.pageSize,
              pageIndex: query.pageIndex,
            });

            const newQuery = {
              filter: this.filterBuild(query.filter),
              pageIndex: query.pageIndex,
              pageSize: query.pageSize,
              sort: { status: 'ASC' },
            };

            const res  = await this.fetchPractitioner(
              newQuery,
            );
            const {practitioners, page} = res;

            this.setState({
              loading: false,
              practitioners,
              page,
            });
          }
        });
    } catch (error) {
      console.error('Failed', error);
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    if (this.filterObs) {
      this.filterObs.unsubscribe();
    }
    if (this.queryObs) {
      this.queryObs.unsubscribe();
    }
  }

  render() {
    const {
      loading,
      practitioners,
      page,
      filter,
      pageIndex,
      pageSize,
    } = this.state;
    // props
    const { openModal, closeModal } = this.props;
    // events
    const { onDelete, onFilterChanges, onPageChange, onPageSizeChange } = this;

    return (
      <React.Fragment>
        <div className="card-header py-3 d-flex justify-content-between align-items-center">
          <h6 className="m-0 font-weight-bold text-primary">Practitioners</h6>
        </div>
        <div className="card-body">
          <PractititionerToolbar
            filter={filter}
            onFilterChanges={onFilterChanges}
          />
          {practitioners.length > 0 ? (
            <PractitionerTableView
              practitioners={practitioners}
              openModal={openModal}
              closeModal={closeModal}
              onDelete={onDelete}
            />
          ) : loading ? (
            <Loading />
          ) : (
                <p className="text-center mt-4 pt-4">No records</p>
              )}
          <TablePagination
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            pageIndex={pageIndex}
            pageSize={pageSize}
            page={page}
          />
        </div>
      </React.Fragment>
    );
  }
}

class PractitionerHomeContainer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="card shadow mb-4">
        <Switch>
          <Route path="/practitioners/:id">
            <PractitionerEditContainer {...this.props} />
          </Route>
          <Route path="/practitioners">
            <PractitionerListContainer {...this.props} />
          </Route>
        </Switch>
      </div>
    );
  }
}

export default PractitionerHomeContainer;
