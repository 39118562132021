export const PAGE_SIZE_DEFAULT = 10;
export const FEATURE_LIST = [
    'product_attribute_VIDEOBASE',
    'product_attribute_SECUREDHOST',
    'product_attribute_OFFICEURL',
    'product_attribute_RENDEZVOUS',
    'product_attribute_TRAINING',
    'product_attribute_WAITINGROOM',
    'product_attribute_PAYMENT',
    'product_attribute_DOCUMENTSHARING',
    'product_attribute_PRESCRIPTION',
    'product_attribute_EDITREPORT',
    'product_attribute_PHOTO',
    'product_attribute_CHAT',
    'product_attribute_INVOICING',
    'product_attribute_SUPPORTBASE',
    'product_attribute_ACCESSBASE',
    'product_attribute_SMSCREATEAPPOINTMENT',
    'product_attribute_LIMITATIONTIMEVIDEOCALL',
]

export const FEATURE_LIST_PRO_PLAN = [
    'product_attribute_VIDEOBASE',
    'product_attribute_SECUREDHOST',
    'product_attribute_OFFICEURL',
    'product_attribute_RENDEZVOUS',
    'product_attribute_TRAINING',
    'product_attribute_WAITINGROOM',
    'product_attribute_PAYMENT',
    'product_attribute_DOCUMENTSHARING',
    'product_attribute_PRESCRIPTION',
    'product_attribute_EDITREPORT',
    'product_attribute_PHOTO',
    'product_attribute_CHAT',
    'product_attribute_INVOICING',
    'product_attribute_SUPPORTBASE',
    'product_attribute_ACCESSBASE',
]
