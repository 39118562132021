import ReactDOM from "react-dom";
import App from "./App";
import 'bootstrap';
import 'react-bootstrap-table'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
// import './assets/styles/previews.css'
import './assets/styles/sb-admin-2.css'
import './assets/styles/z_admin.css'
import '../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

ReactDOM.render(
  App,
  document.getElementById("root")
);
