/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { buildFilterParams, SwalAlert } from '../../helpers';
import Api from '../../api/core/base';
import { PAGE_SIZE_DEFAULT } from '../../constants';
import {Loading, TablePagination} from '../../components';
import { Link } from 'react-router-dom';
import CreateFeatureComponent from './components/CreateFeatureComponent'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import FeatureService from '../../api/features'
const grid = 8;


const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? '' : '',
  padding: grid,
  width: '100%',

});
const getItemStyle = (draggableStyle, isDragging) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  fontSize: '12px',
  border: '1px solid #dee2e6',
  // change background colour if dragging
  background: isDragging ? '' : '',

  // styles we need to apply on draggables
  ...draggableStyle
});
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

class ManageFeatureContainer extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      features: [],
      pageIndex: 1,
      pageSize: 99,
      page: null,
      totalPages: 0,
      filter: {},
      sort: { field: 'order', direction: 'ASC' },
      loading: false,
      title: ''
    }
  }

  async getListManageFeatures({ filter, pageIndex, pageSize, sort }) {
    const params = buildFilterParams(filter, { pageIndex, pageSize }, sort);
    try {
      // const res = await api.get('/features', { params });
      const res = await FeatureService.getFeatures(params)
      return res ? res.data : [];

    } catch (error) {

    }
  }

  componentDidMount = async () => {
    await this.handleGetData()
  }

  componentDidUpdate = async (prevProps, prevState, snapshot) => {
    if (prevState.pageIndex !== this.state.pageIndex) {
      await this.handleGetData();
    }
  }

  handleGetData = async () => {
    let requestBody;
    let { features, totalPages, ...dataBody } = this.state
    requestBody = dataBody
    this.setState({
      loading: true
    })
    let promise = new Promise(async (resolve, reject) => {
      const { data } = await this.getListManageFeatures(requestBody);
      console.log(data)
      resolve(data)
    }).then(result => {
      let { page, totalPages } = result

      this.setState({
        features: result.features,
        totalPages: page.totalPages,
        loading: false,
        page
      })
    })
  }

  onChangeData = (data) => {
    this.setState({ name: data })
  }

  onCreateSuccess = async () => {
    await this.handleGetData()
  }

  create = () => {
    this.props.openModal({
      title: 'Create Feature',
      content: <CreateFeatureComponent
        editer={false}
        onAction={this.onChangeData}
        closeModal={this.closeModal}
        onSuccess={this.onSuccess} />,
      style: {
        'width': '450px'
      }
    })
  }

  closeModal = () => {
    this.props.closeModal()
  }

  onDeleteSuccess = () => {
    SwalAlert.success('Delete success', async () => {
      await this.handleGetData()
    })
  }

  removeItem = (id) => {
    SwalAlert.confirm('Are you sure remove item', async (data) => {
      const res = await Api.delete(`/feature/${id}`)
      if (res) {
        this.onDeleteSuccess()
      }
    }, (cancel) => { })
  }

  onSuccess = async () => {
    await this.handleGetData()
  }

  editItem = (id) => {
    this.props.openModal({
      title: `Edit name of feature (id: ${id})`,
      content: <CreateFeatureComponent
        id={id}
        onAction={this.onChangeData}
        closeModal={this.closeModal}
        onSuccess={this.onSuccess} />,
      style: {
        'width': '450px'
      }
    })
  }

  onDragEnd = async (result) => {
    // dropped outside the list
    console.log('On drag end');
    if (!result.destination) {
      return;
    }

    const { features } = this.state
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index

    features[sourceIndex].order = features[destinationIndex].order

    let dataSwap = reorder(
      features,
      sourceIndex,
      destinationIndex
    );

    dataSwap = dataSwap.map((item, index) => {
      if (destinationIndex < index && index <= sourceIndex) {
        item.order++
      }
      return item
    })

    this.setState({
      features: dataSwap
    }, () => {
      let recods = [...dataSwap]
      recods = recods.map((item) => {
        return {
          id: item.id,
          order: item.order
        }
      })
      this.updateOrders(recods)
    });


  }
  onPageChange = (pageIndex) => {
    this.setState({...this.state, pageIndex: pageIndex, page: {...this.state.page, number: pageIndex}})
  };

  onPageSizeChange = (pageSize) => {
    this.setState({...this.state, pageSize: pageSize})
  };
  updateOrders = async (data) => {
    await FeatureService.updateOrders(data);
  }


  render() {
    const { features, totalPages, pageIndex, loading } = this.state;

    return <div className="card shadow mb-4">
      <div className="d-flex p-3 border-bottom bg-dark text-white">
        <h2 className="flex-grow-1 text-center">Manage features</h2>
        <button className="btn btn-secondary" onClick={this.create}>Create</button>
      </div>

      {
        !features.length > 0 || loading
          ? <div style={{ position: 'absolute', top: '2rem', left: '50%' }}><Loading /></div>
          :
          <div>
            <div className="d-flex px-4 mt-5 font-weight-bold text-capitalize">
              <h4 className="mr-4">#</h4>
              <h3 className="flex-grow-1 font-weight-bold text-capitalize">name</h3>
              <h3>action</h3>
            </div>
            <DragDropContext onDragEnd={async (e) => {await this.onDragEnd(e)}}>
              <Droppable droppableId="characters">
                {
                  (provided, snapshot) => (
                    <div ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                      {...provided.droppableProps}
                    >
                      {
                        features.map((item, index) => (
                          <Draggable
                            key={item.id}
                            draggableId={item.id}
                            index={index}
                          >
                            {
                              (provided, snapshot) => (
                                <div>
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.dragHandleProps}
                                    {...provided.draggableProps}
                                    style={getItemStyle(
                                      provided.draggableProps.style,
                                      snapshot.isDragging
                                    )}
                                  >
                                    {/* content */}
                                    <div className="d-flex align-items-center">
                                      <h5 className="mr-4">{index} -</h5>
                                      <input className="flex-grow-1 border-0"
                                        style={{ pointerEvents: 'none' }}
                                        value={item.name} readOnly={true}/>
                                      <div className="">
                                        <a className="btn btn-primary m-1" href="#"
                                          onClick={() => this.editItem(item.id)}
                                        ><i className="fas fa-pen"></i></a>
                                        <a className="btn btn-danger m-1" href="#"
                                          onClick={() => this.removeItem(item.id)}
                                        ><i className="fa fa-archive"
                                        ></i></a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            }
                          </Draggable>
                        ))
                      }
                      {provided.placeholder}
                    </div>
                  )
                }
              </Droppable>

            </DragDropContext>
          </div>

      }
    </div>
  }
}

export default ManageFeatureContainer