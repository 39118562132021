import React, { Component } from 'react';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, startWith } from 'rxjs/operators';
import Api from '../../api/core/base';
import { Loading, TablePagination } from '../../components';
import { PAGE_SIZE_DEFAULT } from '../../constants';
import { buildFilterParams, SwalAlert } from '../../helpers';
import PlanAddContainer from './PlanAddContainer';
import PlanTableView from './PlanTableView';
import PlanToolbar from './PlanToolbar';
class PlanListContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            plans: [],
            loading: true,
            page: null,
            pageIndex: 1,
            pageSize: PAGE_SIZE_DEFAULT,
            filter: {},
            usedPlan: [],
        }
    }
    componentDidMount = async () => {
        // request API
        await this.fetchPlans()
    }

    fetchPlans = async () => {
        try {
            this.setState({ loading: true });
            // setup

            this.filterSubject = new BehaviorSubject(null);
            this.querySubject = new BehaviorSubject(null);

            this.filterObs = this.filterSubject
                .asObservable()
                .pipe(debounceTime(500))
                .subscribe((filter) => {
                    if (filter) {
                        this.setState({ filter });
                        this.querySubject.next({ filter });
                    }
                });

            this.queryObs = this.querySubject
                .asObservable()
                .pipe(
                    startWith({ filter: {}, pageIndex: 1, pageSize: PAGE_SIZE_DEFAULT }),
                )
                .subscribe(async (query) => {
                    if (query) {
                        // update pageSize or filter
                        if (query.pageSize || query.filter) {
                            // reset page index
                            query.pageIndex = 1;
                        } else {
                            // default
                            query.pageIndex = query.pageIndex || this.state.pageIndex;
                        }
                        query.filter = query.filter
                            ? this.state.filter
                            : { ...this.state.filter, ...query.filter };
                        query.pageSize = query.pageSize || this.state.pageSize;

                        // update state before calling API
                        this.setState({
                            loading: true,
                            filter: query.filter,
                            pageSize: query.pageSize,
                            pageIndex: query.pageIndex,
                        });



                        const newQuery = {
                            filter: this.filterBuild(query.filter),
                            pageIndex: query.pageIndex,
                            pageSize: query.pageSize,
                            sort: { status: 'ASC' },
                        };
                        let { plans, page } = await this.fetchData(
                            newQuery,
                        );

                        this.setState({
                            loading: false,
                            plans: plans,
                            page,
                        });
                    }
                });
        } catch (error) {
            console.error('Failed', error);
            this.setState({ loading: false });
        }
    }

    componentWillUnmount() {
        if (this.filterObs) {
            this.filterObs.unsubscribe();
        }
        if (this.queryObs) {
            this.queryObs.unsubscribe();
        }
    }

    onFilterChanges = (filter) => {
        this.filterSubject.next(filter);
    };

    onPageChange = (pageIndex) => {
        this.querySubject.next({ pageIndex });
    };

    onPageSizeChange = (pageSize) => {
        this.querySubject.next({ pageSize: pageSize });
    };


    fetchData = async ({ filter, pageIndex, pageSize, sort }) => {
        try {
            const params = buildFilterParams(filter, { pageIndex, pageSize }, sort);
            const res = await Api.get('/plans', { params });
            if (!res) {
                throw new Error()
            }

            //get in use plan
            const inuse_res = await Api.get('/used-plans', {});
            this.setState({
                usedPlan: inuse_res.data
            })
            return res.data

        } catch (error) {
            await SwalAlert.error('Get data Failed', async () => {
                this.setState({ loading: false })
            });
            return {}
        }
    }

    filterBuild = (filter) => {
        const filterParams = {};
        if (!filter) return filterParams;
        const { s, status } = filter;
        if (s && s.length > 0) {
            filterParams['or'] = {
                nickname: s,
            };
        }
        if (status) {
            filterParams['and'] = {
                interval: status,
            };
        }
        return filterParams;
    };

    onDeletePlan = async (id, e) => {
        e.preventDefault();
        this.setState({ loading: true })
        if (!this.state.usedPlan.includes(id)) {
            try {
                const res = await Api.delete(`/plan/${id}`, {});

                if (!res) {
                    throw new Error()
                }

                this.setState({ loading: false })
                await SwalAlert.success('Delete Success', async () => {
                    await this.fetchPlans()
                });
            } catch (error) {
                console.log('[DELETE FAILED : ] ', error)
                await SwalAlert.error('Delete Failed ', async () => {
                    this.setState({ loading: false })
                });
            }
        } else {
            await SwalAlert.error('Can not delete in use Plan', async () => {
                this.setState({ loading: false })
            });
        }
    }

    onAddNewPlan = async (data) => {
        try {
            const res = await Api.post(`/plan`, {
                ...data
            });
            if (res.data) {
                SwalAlert.success('Create Success', async () => {
                    this.props.closeModal()
                    await this.fetchPlans()
                });
            } else {
                SwalAlert.error('Create Failed', () => {
                });
            }

        } catch (error) {
            console.log('[DEBUG ADD PLAN ERROR] ', error)
            SwalAlert.error('Create Failed', () => {
            });
        }
    }

    openDialog = () => {
        this.props.openModal({
            title: `Add new Plan`,
            size: 'large',
            content: (
                <PlanAddContainer
                    {...this.props}
                    onAddNewPlan={this.onAddNewPlan}
                />
            ),
        });
    }

    render() {
        const {
            page,
            filter,
            pageIndex,
            pageSize,
            plans,
            loading,
            searchValue,
            usedPlan,
            features
        } = this.state
        const { onPageSizeChange, onPageChange, onFilterChanges, onDeletePlan, openDialog } = this

        return (
            <>
                <div className="card-header py-3 d-flex justify-content-between align-items-center">
                    <h6 className="m-0 font-weight-bold text-primary">Plans</h6>
                    <div className="buttons">
                        <button
                            className="btn btn-danger mr-2"
                            onClick={openDialog}
                        >Add New Plan</button>
                        {/* <LinkButton href={'/dashboard'}>Back</LinkButton> */}
                    </div>
                </div>
                {
                    loading ? <Loading />
                        : (
                            <div className="card-body">
                                <PlanToolbar
                                    filter={filter}
                                    searchValue={searchValue}
                                    onFilterChanges={onFilterChanges} />
                                <PlanTableView
                                    plans={plans}
                                    featureList={features}
                                    onDeletePlan={onDeletePlan}
                                    usedPlan={usedPlan} />
                                {
                                    plans && plans.length > 0 &&
                                    <TablePagination
                                        onPageSizeChange={onPageSizeChange}
                                        onPageChange={onPageChange}
                                        pageIndex={pageIndex}
                                        pageSize={pageSize}
                                        page={page}
                                    />
                                }
                            </div>
                        )
                }
            </>
        );
    }
}

export default PlanListContainer;
