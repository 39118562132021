/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Redirect } from 'react-router';

const configs = [
  {
    id: 1,
    logo: '1',
    facilityUrl: 'https://www.google.com/',
    languageInterface: 'FR',
    nameOfFacility: 'Name 1',
    // phone
    countryCode: '+33',
    phoneNumber: '356246512',
    // pictutesFacility
    format: '1',
    maxSize: '1',
    limit: '1',
    // address facility
    street: 'Binh Thanh',
    postalCode: '65000',
    city: 'Ho Chi Minh',
    country: 'VN',
    numberOfLicenses: '50',
    ownerEmail: 'ex@yopmail.com',
    endDay: 1658923132,
  },
  {
    id: 2,
    logo: '1',
    facilityUrl: 'https://www.google.com/',
    languageInterface: 'FR',
    nameOfFacility: 'Name 1',
    // phone
    countryCode: '1',
    phoneNumber: '1',
    // pictutesFacility
    format: '1',
    maxSize: '1',
    limit: '1',
    // address facility
    street: '1',
    postalCode: '1',
    city: '1',
    country: '1',
    numberOfLicenses: '50',
    ownerEmail: 'ex@yopmail.com',
    endDay: 1658923132,
  },
  {
    id: 3,
    logo: '1',
    facilityUrl: 'https://www.google.com/',
    languageInterface: 'FR',
    nameOfFacility: 'Name 1',
    // phone
    countryCode: '1',
    phoneNumber: '1',
    // pictutesFacility
    format: '1',
    maxSize: '1',
    limit: '1',
    // address facility
    street: '1',
    postalCode: '1',
    city: '1',
    country: '1',
    numberOfLicenses: '50',
    ownerEmail: 'ex@yopmail.com',
    endDay: 1658923132,
  }
]


function GroupManagementContainer(props) {
  const history = useHistory();

  function goToGroupById(id) {
    history.push(`/group-users/${id}`)
  }

  return <div className="card shadow mb-4">
    <div className="d-flex p-3 border-bottom bg-dark text-white">
      <h2 className="flex-grow-1 text-center">Group Management</h2>
      <button className="btn btn-secondary">Create</button>
    </div>
    <table className="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Language Interface</th>
          <th scope="col">Group Name</th>
          <th scope="col">End Day</th>
          <th scope="col">Owner's email</th>
          <th scope="col">URL Facility</th>
          <th scope="col">Number of licences</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>

      {
        !configs.length < 0 ? '1' :
          <tbody>
            {
              configs.map((item, index) => {
                return (
                  <tr key={index} onClick={(e) => { e.preventDefault(); goToGroupById(item.id) }} style={{ cursor: 'pointer' }}>
                    <td>{index}</td>
                    <td>{item.languageInterface}</td>
                    <td>{item.nameOfFacility}</td>
                    <td>{item.endDay}</td>
                    <td>{item.ownerEmail}</td> {/* owner */}
                    <td>{item.facilityUrl}</td>
                    <td>{item.numberOfLicenses}</td>
                    <td colSpan="2">
                      <div className="d-flex">
                        <a className="btn btn-primary m-1" href="#"
                          onClick={() => this.editItem(item.id)}
                        ><i className="fas fa-pen"></i></a>
                        <a className="btn btn-danger m-1" href="#"
                          onClick={() => this.removeItem(item.id)}
                        ><i className="fa fa-archive"
                        ></i>
                        </a>
                      </div>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
      }

    </table>
  </div>

}

export default GroupManagementContainer