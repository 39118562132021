/* eslint-disable no-useless-constructor */
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { LoginContainer } from "./containers";
import Sidebar from "./layout/sidebar/Sidebar";
import Topbar from "./layout/topbar/Topbar";
import Routes from "./routes";
import history from "./routes/history";
import './App.scss'


const Modal = (props) => {
  const { modal, closeModal } = props; //disabledClickOutside
  const modelSize = {
    small: "modal-sm",
    default: "",
    large: "modal-lg",
    "ex-large": "modal-xl",
  };
  const cssClass = modal
    ? "modal fade bd-example-modal-xl show"
    : "modal fade bd-example-modal-xl";
  let modalRef = null;
  // let outsideRef = null;
  const setModalRef = (element) => {
    modalRef = element;
  };
  const handleClickOutside = (e) => {
    const targetElement = e.target;
    if (targetElement === modalRef && !modal.disabledClickOutside) {
      closeModal();
    }
  };
  const handleEsc = (evt) => {
    evt = evt || window.event;
    if (evt.keyCode === 27) {
      closeModal();
    }
  };
  document.onkeydown = handleEsc;
  if (!modal) return "";
  return (
    <React.Fragment>
      <div>
        <div
            className={cssClass}
            tabIndex="-1"
            role="dialog"
            onClick={handleClickOutside}
            ref={setModalRef}
            style={{ height: "100%" }}
        >
          <div
              className={`modal-dialog ${modelSize[modal.size || "ex-large"]}`}
              style={modal.style}
              role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{modal.title}</h5>
                <button
                    type="button"
                    onClick={closeModal}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">{modal.content}</div>
            </div>
          </div>
        </div>
        <div
            className={modal ? "modal-backdrop fade show" : "modal-backdrop fade"}
        ></div>
      </div>
    </React.Fragment>
  );
};

const AppModuleContainer = () => {
  // hooks
  const [modal, setModal] = React.useState(null);
  React.useEffect(() => {
    if (modal) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  });
  const openModal = (modal) => {
    setModal(modal);
  };
  const closeModal = () => {
    setModal(null);
  };
  const token =
    localStorage.getItem("ca-app-admin-token") ||
    sessionStorage.getItem("ca-app-admin-token");

  console.error(process.env);

  return (
    <React.Fragment>
      {token ? (
        <div id="wrapper" >
          <Sidebar openModal={openModal} closeModal={closeModal} />
          <div id="content-wrapper" className="d-flex flex-column">
            <Topbar />
            <div className="container-fluid px-4">
              <Routes openModal={openModal} closeModal={closeModal} />
            </div>
          </div>
        </div>
      ) : (
        <LoginContainer />
      )}
      <Modal modal={modal} closeModal={closeModal} />
    </React.Fragment>
  );
};
// BASENAME
const BASENAME = process.env.REACT_APP_BASENAME || "";
console.error("BASENAME", BASENAME);
const App = (
  <Router basename={BASENAME} history={history}>
    <AppModuleContainer />
  </Router>
);

export default App;
