import * as qs from 'qs';
const axios = require('axios').default;

// const BASE_URL = 'http://localhost:1337/api'

const BASE_URL = process.env.REACT_APP_API_ENDPOINT

const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    common: {
      Authorization: localStorage.getItem(
        'ca-app-admin-token',
      ) || sessionStorage.getItem("ca-app-admin-token")
    },
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  }
});
/**
 * Function to check expiration of token , if token is expired will be
 * redirect to login page.
 */
const checkIfTokenExpired = async () => {
  try {
    await api.get('/auth/check-token-expired');
  } catch (err) {
    const token = localStorage.getItem("ca-app-admin-token");
    if (err.response && err.response.status === 403 && token) {
      console.log('Check token expired', err.response);
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload();
    }
  }



}


// api.defaults.baseURL = BASE_URL || '/';
// api.defaults.headers.post['Content-Type'] = 'application/json';

const BaseAPI = {
  get: async (path = '', params = {}) => {
    console.log('before qs', params);
    let queryParams = params.params ? params.params : params;
    queryParams = qs.stringify(queryParams, { addQueryPrefix: true, encode: false, indices: false });
    console.log('Query', queryParams);
    await checkIfTokenExpired();
    const res = api.get(`${BASE_URL}${path}${queryParams}`);
    return res ? res : [];
  },

  post: async (path, params = {}) => {
    await checkIfTokenExpired();
    const res = api.post(`${BASE_URL}${path}`, params);
    return res ? res : [];
  },

  patch: async (path, params = {}) => {
    await checkIfTokenExpired();
    const res = api.patch(`${BASE_URL}${path}`, params);
    return res ? res : [];
  },
  delete: async (path, params = {}) => {
    await checkIfTokenExpired();
    const res = api.delete(`${BASE_URL}${path}`, params);
    return res ? res : [];
  }

}

const RESOURCES = {
  GET_PRACTITIONER_LANGUAGES: '/languages/get-languages', // ?langId=en
  GET_PRACTITIONER_SPECIALITIES: '/practitioner-specialities',
  GET_SUBSCRIPTION: '/subscriptions',
  GET_SUBSCRIPTION_PLANS: '/plan',
  GET_SETTINGS_CONFIG: '/setting',
  GET_LOG_CODE_VERIFY: '/logs',
  ARCHIVE_PRACTITIONER: '/practitioner/archive',
  GET_FEATURES: '/feature',
  PARTNERS: '/partner',
  SUPPORT_LANGUAGES: '/support-language'
};
const ApiStore = {
  getPractitionerLanguages: async (langId) => {
    const res = await BaseAPI.get(RESOURCES.GET_PRACTITIONER_LANGUAGES, {
      langId,
    });
    return res ? res.data.records : [];
  },
  getPractitionerSpecialities: async (langId) => {
    const res = await BaseAPI.get(RESOURCES.GET_PRACTITIONER_SPECIALITIES, {
      langId,
    });
    return res ? res.data.records : [];
  },
  getSubscriptionPlans: async () => {
    const res = await BaseAPI.get(RESOURCES.GET_SUBSCRIPTION_PLANS, {});
    return res ? res.data : [];
  },
  getSettingConfig: async () => {
    const res = await BaseAPI.get(RESOURCES.GET_SETTINGS_CONFIG, {});
    return res ? res.data : [];
  },
  getSubscriptions: async () => {
    const res = await BaseAPI.get(RESOURCES.GET_SUBSCRIPTION, {});
    return res ? res.data : [];
  },
  getLogCodeVerify: async () => {
    const res = await BaseAPI.get(RESOURCES.GET_LOG_CODE_VERIFY, {});
    return res ? res.data : [];
  },

  archivePractitioner: async (practitionerId) => {
    const res = await BaseAPI.post(`${RESOURCES.ARCHIVE_PRACTITIONER}/${practitionerId}`, {});
    return res ? res.data : [];
  },
  getFeatures: async () => {
    const res = await BaseAPI.get(RESOURCES.GET_FEATURES, {});
    return res ? res.data : [];
  },
  getPartners: async () => {
    const res = await BaseAPI.get(RESOURCES.PARTNERS, {});
    return res ? res.data : [];
  },
  createPartner: async (payload) => {
    const res = await BaseAPI.post(RESOURCES.PARTNERS, {
      ...payload
    });
    return res ? res.data : [];
  },
  editPartner: async (payload) => {
    const res = await BaseAPI.patch(RESOURCES.PARTNERS, {
      ...payload
    });
    return res ? res.data : [];
  },
  deletePartner: async (payload) => {
    const res = await BaseAPI.delete(RESOURCES.PARTNERS, { data: { id: payload.id } })
    return res ? res.data : [];
  },
  getSupportLanguages: async () => {
    const res = await BaseAPI.get(RESOURCES.SUPPORT_LANGUAGES, {})
    return res ? res.data : [];
  }
};
const Api = {
  ...ApiStore, ...BaseAPI
}
export default Api;