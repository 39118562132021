import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import api from '../../api';
import { Loading } from '../../components';
import { SwalAlert } from '../../helpers';
import AdminAccountListContainer from './AdminAccountListContainer';


class AdminAccountHomeContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            adminAccount: [],
        }
    }


    render() {
        return (
            <div className="card shadow mb-4">
                <Switch>
                    <Route path="/accounts">
                        <AdminAccountListContainer {...this.props} />
                    </Route>
                </Switch>
            </div>
        );
    }
}

export default AdminAccountHomeContainer;
