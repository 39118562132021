import React, { Component } from 'react';
import api from '../../api';


import { withRouter } from 'react-router-dom';

class DetailLogContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {}
    }

    console.log(this.props);

  }

  componentDidMount = async () => {
    // let { id } = useParams();
    //     console.log(id)
    const { data } = await this.getDetailCodeVerify()
    this.setState({
      data
    })
  }


  async getDetailCodeVerify() {
    const { id } = this.props.match.params
    try {
      const res = await api.get(`/log/${id}`, {});
      return res ? res.data : [];
    } catch (error) {

    }
  }
  render() {
    const { data } = this.state;
    const { createdAt } = data
    return (
      <main className="container card shadow mt-lg-5">
        <div className="row mt-4 mb-4">
          <div className="col-md-3 border-right d-flex flex-column">
            <div className="group-top flex-grow-1">
              <h6>App</h6>
              {/* <h6>Created by</h6> */}
              <h6>Data</h6>
            </div>
            <h6 className="mb-0">Request Date</h6>
          </div>
          <div className="col-md-9 d-flex flex-column">
            <b>MailJet Mail</b>
            {/* <b>123</b> */}
            <span>{JSON.stringify(data)}</span>
            <span className="mt-5">{new Intl.DateTimeFormat('en-US', {
              year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'
            }).format(createdAt)}</span>

          </div>
        </div>
      </main>
    )
  }
}

export default withRouter(DetailLogContainer);