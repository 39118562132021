import { yupResolver } from '@hookform/resolvers/yup';
import React, { PureComponent } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import Api from '../../api/core/base';
import { SwalAlert } from '../../helpers';

const changePasswordSchema = yup.object({
  password: yup.string().required('Password is required').test('len', 'Must be at least 6 characters', val => val.length >= 6),
  repeatPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

const PractitionerChangePasswordForm = (props) => {
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(changePasswordSchema),
  });
  const { closeModal, practitioner, onSave } = props;
  const onUpdatePassword = (data, e) => {
    e.preventDefault();
    onSave(practitioner.id, data);
  };
  return (
    <section className="row justify-content-center">
      <form className="col-4" onSubmit={handleSubmit(onUpdatePassword)}>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            className="form-control"
            id="password"
            name="password"
            ref={register}
            placeholder="Password"
          />
          {errors.password && (
            <div className="form-error error" role="alert">
              {errors.password.message}
            </div>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="repeatPassword">Repeat Password</label>
          <input
            type="password"
            className="form-control"
            id="repeatPassword"
            name="repeatPassword"
            ref={register}
            placeholder="Repeat Password"
          />

          {errors.repeatPassword && (
            <div className="form-error error" role="alert">
              {errors.repeatPassword.message}
            </div>
          )}
        </div>
        <div className="text-right">
          <button
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}
            className="btn btn-primary col-4 mr-2"
          >
            Cancel
          </button>
          <button
            type="submit"
            onSubmit={handleSubmit(onUpdatePassword)}
            className="btn btn-danger col-4"
          >
            Save
          </button>
        </div>
      </form>
    </section>
  );
};

class PractitionerChangePasswordContainer extends PureComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => { };

  onSave = async (id, payload) => {
    this.setState({
      loading: true,
    });
    await Api.patch(`/practitioner/${id}`, {
      id,
      ...payload,
    });
    this.setState({
      loading: false,
    });
    SwalAlert.success('Update Success', () => { });
  };

  render() {
    return (
      <PractitionerChangePasswordForm {...this.props} onSave={this.onSave} />
    );
  }
}

export default PractitionerChangePasswordContainer;
