import Api from './core/base';
const GET_FEATURES = '/features';
const UPDATE_FEATURE_ORDERS = '/feature/orders'

const FeatureService = {
  getFeatures: (params = {}) => {
    return Api.get(GET_FEATURES, params)
  },
  updateOrders: (data= {}) => {
    return Api.post(UPDATE_FEATURE_ORDERS, data)
  }
}

export default FeatureService