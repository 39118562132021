import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import  Api from '../../api/core/base';
import PlanListContainer from './PlanListContainer';
import PlanViewContainer from './PlanViewContainer';
class PlanHomeContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            features: [],
        }
    }

    componentDidMount = async () => {
        await this.fetchFeatures()
    }

    fetchFeatures = async () => {
        try {
            const res = await Api.get('/feature', {});
            if (!res) {
                throw new Error()
            }

            const featureList = res.data.map((f) => f.id)

            this.setState({
                features: featureList
            })
        } catch (error) {

        }
    }

    render() {
        const { features } = this.state
        return (
            <div className="card shadow mb-4">
                <Switch>
                    <Route path="/plans/:id">
                        <PlanViewContainer {...this.props} featureList={features} />
                    </Route>
                    <Route path="/plans">
                        <PlanListContainer {...this.props} featureList={features} />
                    </Route>
                </Switch>
            </div>
        );
    }
}

export default PlanHomeContainer;
