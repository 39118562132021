/* eslint-disable no-useless-constructor */
import React, { PureComponent } from 'react';

import PractitionerViewContainer from './PractitionerViewContainer';
import PractitionerChangePasswordContainer from './PractitionerChangePasswordContainer';

import { LinkButton } from '../../components';

class PractitionerTableView extends PureComponent {
  constructor(props) {
    super(props);
  }

  viewPractitioner = (practitioner) => {
    this.props.openModal({
      title: `View Practitioner - ${practitioner.id} - ${practitioner.firstname}`,
      content: <PractitionerViewContainer practitioner={practitioner} />,
    });
  };

  changePassword = (practitioner) => {
    this.props.openModal({
      title: `Change Practitioner Password - ${practitioner.id} - ${practitioner.firstname}`,
      content: (
        <PractitionerChangePasswordContainer
          {...this.props}
          practitioner={practitioner}
        />
      ),
    });
  };

  render() {
    // params
    const { practitioners, onDelete } = this.props;
    // open this to allow dev
    // const prac = practitioners.filter((p) => p.id === 1603875564896);
    // setTimeout(() => {

    //   this.changePassword(prac[0]);
    // }, 500);
    // -- end dev with modal

    const displayColumns = [
      'id',
      'firstname',
      'lastname',
      'email',
      'app_id',
      'actions',
    ];
    return (
      <React.Fragment>
        <table className="table table-bordered table-hover mt-5">
          <thead>
            <tr>
              {displayColumns.map((c, h_idx) => (
                <th
                  style={{ width: 100 / displayColumns.length + '%' }}
                  scope="col"
                  key={h_idx}
                >
                  {c}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {practitioners.map((r, r_idx) => (
              <tr key={r_idx}>
                {displayColumns
                  .filter((c) => c != 'actions')
                  .map((c, c_idx) => (
                    <td key={c_idx}>{r[c]}</td>
                  ))}
                <td className="ca-admin-table-actions">
                  <LinkButton
                    className="btn btn-primary"
                    onClick={(e) => {
                      this.viewPractitioner(r);
                    }}
                    title="View"
                  >
                    <i className="fas fa-eye" aria-hidden="true"></i>
                  </LinkButton>
                  <LinkButton
                    className="btn btn-primary"
                    onClick={(e) => {
                      this.changePassword(r);
                    }}
                    title="Change Password"
                  >
                    <i className="fas fa-key" aria-hidden="true"></i>
                  </LinkButton>
                  {
                    r.status === 'active' && (
                      <>
                        <LinkButton
                          href={'/practitioners/' + r.id}
                          className="btn btn-primary"
                          title="Edit"
                        >
                          <i className="fas fa-pen" aria-hidden="true"></i>
                        </LinkButton>
                        <LinkButton
                          onClick={(e) => {
                            onDelete(e, r.id);
                          }}
                          className="btn btn-danger"
                          title="Archive"
                        >
                          <i className="fa fa-archive" aria-hidden="true"></i>
                        </LinkButton>
                      </>
                    )
                  }
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </React.Fragment>
    );
  }
}

export default PractitionerTableView;
