import React, { Component } from 'react';
import ConfigViewContainer from './ConfigViewContainer';

class ConfigHomeContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        console.log('Setting setting');
        return (
            <div className="card shadow mb-4">
                <ConfigViewContainer {...this.props} />
            </div>
        );
    }
}

export default ConfigHomeContainer;
