/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Api from '../../api/core/base';
import { Loading } from '../../components';
import { PAGE_SIZE_DEFAULT } from '../../constants';
import { buildFilterParams } from '../../helpers';
import BootstrapTable from 'react-bootstrap-table-next';
class LogContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logs: [],
      page: null,
      pageIndex: 0,
      pageSize: PAGE_SIZE_DEFAULT,
      totalPages: 0,
      filter: {},
      sort: { field: 'updatedAt', direction: 'DESC' },

      loading: false
    };
  }


  async getLogCodeVerify({ filter, pageIndex, pageSize, sort }) {
    const params = buildFilterParams(filter, { pageIndex, pageSize }, sort);
    try {
      const res = await Api.get('/logs', { ...params });
      return res ? res.data : [];
    } catch (error) {

    }
  }


  componentDidMount = async () => {
    await this.handleGetData()
  }

  handleGetData = async () => {
    let requestBody;
    let { logs, totalPages, ...dataBody } = this.state
    delete dataBody.page
    requestBody = dataBody
    this.setState({
      loading: true
    })
    let promise = new Promise(async (resolve, reject) => {
      const { data } = await this.getLogCodeVerify(requestBody);
      resolve(data)
    }).then(result => {
      let { page } = result
      this.setState({
        ...result,
        pageIndex: page.number,
        totalPages: page.totalPages,
        loading: false

      })
    })
  }

  gotoDetail(id) {
    console.log(id)
  }

  nextPage = () => {
    let { pageIndex, totalPages } = this.state
    if (pageIndex < totalPages) {
      this.setState({
        pageIndex: ++pageIndex
      }, () => {
        this.handleGetData()
      })
    }
  }

  previousPage = () => {
    let { pageIndex } = this.state
    if (pageIndex > 1) {
      this.setState({
        pageIndex: --pageIndex
      }, () => {
        this.handleGetData()
      })
    }
  }

  render() {
    const { logs, totalPages, pageIndex, loading } = this.state;
    // if (!logs.length > 0 || loading)

    return (
      <div className="card shadow mb-4">
          <div className="container border-bottom">
            <div class="row p-2">
              <h6 className="col-1 font-weight-bold">#</h6>
              <h6 className="col-2 font-weight-bold">MailJet Mail</h6>
              <h6 className="col-2 font-weight-bold">Request Date</h6>
              <h6 className="col-7 font-weight-bold">Response</h6>
            </div>
          </div>
          {
            !logs.length > 0 || loading
              ? <div style={{ position: 'absolute', top: '2rem', left: '50%' }}><Loading /></div>
              :
              <>
                {logs.map((value, index) => {
                  const { id } = value
                  return <div className="container border-bottom">
                    <div class="row">
                      <div className="col-1">
                        <Link to={`log/${id}`}>
                          {index + 1}
                        </Link>
                      </div>
                      <div className="col-2">App</div>
                      <div className="col-2">{new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(value.updatedAt)}</div>
                      <div className="col-7">  {JSON.stringify(value.messages)}</div>
                    </div>
                  </div>
                })}
              </>
          }
        <nav aria-label="Page navigation example">
          <ul className="pagination d-flex align-items-center justify-content-end mr-4">
            <li onClick={this.previousPage}
              className={pageIndex === 1 ? 'page-item disabled' : 'page-item'} >
              <a className="page-link" ariaLabel="Previous">
                <span aria-hidden="true">&laquo;</span>
              </a>
            </li>
            <span className="m-1">
              {pageIndex}/{totalPages}
            </span>
            <li onClick={this.nextPage}
              className={pageIndex === totalPages ? 'page-item disabled' : 'page-item'}>
              <a className="page-link" aria-label="Next">
                <span aria-hidden="true">&raquo;</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    )
  }
}

export default LogContainer;
