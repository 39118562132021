/* eslint-disable jsx-a11y/anchor-is-valid */
const configs = [
  {
    user: {
      id: '1',
      firstName: 'Teo',
      lastName: '',
    },
    role: 'user',
    atribute: ''
  },
  {
    user: {
      id: '2',
      firstName: 'Ti',
      lastName: '',
    },
    role: 'user',
    atribute: ''
  }
]

const GroupUsersContainer = () => {
  return <>
        <div className="d-flex p-3 border-bottom bg-dark text-white">
          <h2 className="flex-grow-1 text-center">Group Users</h2>
        </div>
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">First Name</th>
              <th scope="col">Last Name</th>
              <th scope="col">Role</th>
              <th scope="col">Atribute</th>
              <th scope="col">Actions</th>

            </tr>
          </thead>

          {
            !configs.length < 0 ? 'Loading...' : 
            <tbody>
              {
                configs.map( (item, index) => {
                  return (
                    <tr key={index} style={{ cursor: 'pointer'}}>
                      <td>{index}</td>
                      <td>{item.user.firstName}</td>
                      <td>{item.user.lastName}</td>
                      <td>{item.role}</td>
                      <td>{item.atribute}</td>
       
                      <td colSpan="2">
                        <div className="d-flex">
                            <a className="btn btn-primary m-1" href="#"
                              onClick={() => this.editItem(item.id)}
                              ><i className="fas fa-pen"></i></a>
                              <a className="btn btn-danger m-1" href="#"
                                onClick={() => this.removeItem(item.id)}
                              ><i className="fa fa-archive" 
                              ></i>
                            </a>
                        </div>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          }

      </table>
  </>
}

export default GroupUsersContainer