import React, { Component } from 'react';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, startWith } from 'rxjs/operators';
import api from '../../api';
import { LinkButton, Loading, TablePagination } from '../../components';
import { PAGE_SIZE_DEFAULT } from '../../constants';
import { buildFilterParams, SwalAlert } from '../../helpers';
import SubscriptionTableView from './SubscriptionTableView';
import SubscriptionToolbar from './SubscriptionToolbar';


class SubscriptionListContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subscriptions: [],
            loading: true,
            page: null,
            pageIndex: 1,
            pageSize: PAGE_SIZE_DEFAULT,
            filter: {},
            searchValue: '',
            practitioners: [],
            selectedPractitionerName: '',
            selectedPractitionerId: '',
            selectedStatus: '',
        }
    }
    onPractitionerChange = (e) => {
        const selectedValue = e.target.value;
        console.log('Prac change', selectedValue)
        this.setState({ ...this.state, selectedPractitionerId: selectedValue })
        this.onFilterChanges({ ...this.state.filter, practitionerId: selectedValue, });
    }
    onStatusChange = (e) => {
        const selectedValue = e.target.value;
        this.setState({ ...this.state, selectedStatus: selectedValue })
        this.onFilterChanges({ ...this.state.filter, status: selectedValue });
    }
    filterData = (practitionersubscriptions) => {
        let { searchValue } = this.state
        searchValue = searchValue.toLowerCase()
        if (!searchValue) return practitionersubscriptions
        return practitionersubscriptions.filter((sup) => {
            return sup.practitioner &&
                (sup.practitioner.firstname.toLowerCase().includes(searchValue) || sup.practitioner.lastname.toLowerCase().includes(searchValue))
        })
    }
    componentDidMount = async () => {
        // request API
        try {
            this.setState({ loading: true });
            // setup

            this.filterSubject = new BehaviorSubject(null);
            this.querySubject = new BehaviorSubject(null);

            this.filterObs = this.filterSubject
                .asObservable()
                .pipe(debounceTime(500))
                .subscribe((filter) => {
                    console.log('Filter', filter);
                    if (filter) {
                        const searchValue = filter.practitionerId;
                        this.setState({ filter, searchValue });
                        this.querySubject.next({ filter });
                    }
                });

            this.queryObs = this.querySubject
                .asObservable()
                .pipe(
                    startWith({ filter: {}, pageIndex: 1, pageSize: PAGE_SIZE_DEFAULT }),
                )
                .subscribe(async (query) => {
                    console.log('Query', query);
                    if (query) {
                        // update pageSize or filter
                        if (query.pageSize || query.filter) {
                            // reset page index
                            query.pageIndex = 1;
                        } else {
                            // default
                            query.pageIndex = query.pageIndex || this.state.pageIndex;
                        }
                        query.filter = query.filter
                            ? this.state.filter
                            : { ...this.state.filter, ...query.filter };
                        query.pageSize = query.pageSize || this.state.pageSize;

                        // update state before calling API
                        this.setState({
                            loading: true,
                            filter: query.filter,
                            pageSize: query.pageSize,
                            pageIndex: query.pageIndex,
                        });



                        const newQuery = {
                            filter: this.filterBuild(query.filter),
                            pageIndex: query.pageIndex,
                            pageSize: query.pageSize,
                            sort: { status: 'ASC' },
                        };
                        let { practitionersubscriptions, page } = await this.fetchData(
                            newQuery,
                        );

                        // practitionersubscriptions = this.filterData(practitionersubscriptions)

                        this.setState({
                            loading: false,
                            subscriptions: practitionersubscriptions,
                            page,
                        });
                    }
                });
        } catch (error) {
            console.error('Failed', error);
            this.setState({ loading: false });
        }
    }

    componentWillUnmount() {
        if (this.filterObs) {
            this.filterObs.unsubscribe();
        }
        if (this.queryObs) {
            this.queryObs.unsubscribe();
        }
    }

    onFilterChanges = (filter) => {
        console.log('On Filter change', filter);
        this.filterSubject.next(filter);
    };

    onPageChange = (pageIndex) => {
        this.querySubject.next({ pageIndex });
    };

    onPageSizeChange = (pageSize) => {
        this.querySubject.next({ pageSize: pageSize });
    };


    fetchData = async ({ filter, pageIndex, pageSize, sort }) => {
        try {
            const params = buildFilterParams(filter, { pageIndex, pageSize }, sort);
            const res = await api.get('/subscriptions', { params });
            if (!res) {
                throw new Error()
            }
            return res.data
        } catch (error) {
            await SwalAlert.error('Get data Failed', async () => {
                this.setState({ loading: false })
            });
            return {}
        }
    }

    filterBuild = (filter) => {
        const filterParams = {};
        if (!filter) return filterParams;
        const { practitionerId, status } = filter;
        if (practitionerId) {
            filterParams['or'] = {
                practitioner_id: parseInt(practitionerId)
            };
        }
        if (status) {
            filterParams['and'] = {
                state: status,
            };
        }
        return filterParams;
    };

    render() {
        const {
            page,
            filter,
            pageIndex,
            pageSize,
            subscriptions,
            loading,
            searchValue
        } = this.state

        const { onPageSizeChange, onPageChange, onPractitionerChange, onStatusChange } = this

        return (
            <>
                <div className="card-header py-3 d-flex justify-content-between align-items-center">
                    <h6 className="m-0 font-weight-bold text-primary">Subscriptions</h6>
                    {/* <div className="buttons">
                        <LinkButton href={'/dashboard'}>Back</LinkButton>
                    </div> */}
                </div>
                {
                    loading ? <Loading />
                        : (
                            <div className="card-body">
                                <SubscriptionToolbar
                                    filter={filter}
                                    searchValue={searchValue}
                                    onPractitionerChange={onPractitionerChange}
                                    onStatusChange={onStatusChange}
                                    selectedPractitionerId={this.state.selectedPractitionerId}
                                    selectedStatus={this.state.selectedStatus}
                                />
                                <SubscriptionTableView
                                    subscriptions={subscriptions} />
                                {
                                    subscriptions && subscriptions.length > 0 &&
                                    <TablePagination
                                        onPageSizeChange={onPageSizeChange}
                                        onPageChange={onPageChange}
                                        pageIndex={pageIndex}
                                        pageSize={pageSize}
                                        page={page}
                                    />
                                }
                            </div>
                        )
                }
            </>
        );
    }
}

export default SubscriptionListContainer;
