/* eslint-disable no-useless-constructor */
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import { LinkButton, Loading } from '../../components';

import  Api  from '../../api/core/base';
import { SwalAlert } from '../../helpers';

import { useForm } from 'react-hook-form';

const PractititionerEditForm = (props) => {
  const { register, handleSubmit, watch, errors, trigger } = useForm();
  const {
    practitioner,
    onSave,
    practitionerSpecialities,
    practitionerLanguages,
    subscriptionPlans,
  } = props;
  const {
    id,
    firstname,
    lastname,
    email,
    phone_country_code,
    phone_number,
    gender,
    profession,
    userType,
    conventionnement,
    practitioner_speciality_ids,
    language_ids,
    vital_card,
    tiers_payant,
    cmu,
    langId,
    app_id,
    enableTwoFA,
    time_frame,
    apply_limitation,
    limitedTimeCall,
    timezone,
    billing_street,
    billing_town,
    billing_zipcode,
    non_regulated_profession,
    offices,
    practitioner_office_ids,
    customerId,
    current_plan_id,
    isSetUpStripe,
    status,
  } = practitioner;

  const toNumbers = arr => arr.map(Number);

  const onSubmit = (data, e) => {
    e.preventDefault();
    const { practitioner_speciality_ids, language_ids, ...rest } = data
    console.log('Data', data);
    const newData = {
      practitioner_speciality_ids: toNumbers(practitioner_speciality_ids),
      language_ids: toNumbers(language_ids),
      ...rest
    }
    onSave(id, newData);
  };

  return (
    <form id="edit-practitioner" onSubmit={handleSubmit(onSubmit)}>
      <React.Fragment>
        <div className="card-header py-3 d-flex justify-content-between align-items-center">
          <h6 className="m-0 font-weight-bold text-primary">
            Edit Practitioner {id}
          </h6>
          <div className="buttons">
            <LinkButton href={'/practitioners'}>Back</LinkButton>
            <button
              className={'btn btn-danger ml-1'}
              title="Save"
              onClick={() => {
                trigger();
              }}
            >
              Save
            </button>
          </div>
        </div>
        <div className="card-body">
          <table className="table table-bordered table-hover mt-5">
            <thead>
              <tr className="table-active">
                <th colSpan="4">Technical Information</th>
              </tr>
              <tr>
                <th>ID</th>
                <td>{id}</td>
                <th>UserType</th>
                <td>{userType}</td>
              </tr>
              <tr>
                <th>AppID</th>
                <td>{app_id}</td>
                <th>LangID</th>
                <td>{langId}</td>
              </tr>
              <tr>
                <th>enableTwoFA?</th>
                <td>
                  <input
                    className="col-1"
                    type="checkbox"
                    ref={register}
                    name="enableTwoFA"
                    defaultChecked={enableTwoFA}
                  />
                </td>
                <th>Timezone</th>
                <td>{timezone}</td>
              </tr>
              <tr>
                <th>Status</th>
                <td>{status}</td>
                <th>Is Setup Stripe?</th>
                <td>
                  <input
                    type="checkbox"
                    disabled={true}
                    className="col-1"
                    checked={isSetUpStripe}
                  />
                </td>
              </tr>
            </thead>
            <tbody>
              <tr className="table-active">
                <th colSpan="4">Plans & Subscriptions</th>
              </tr>
              <tr>
                <th>CustomerID</th>
                <td>{customerId}</td>
                <th>Plan</th>
                <td>
                  {subscriptionPlans
                    .filter((e) => current_plan_id === e.id)
                    .map((e) => (
                      <span data-planid={e.id} key={e.id}>
                        {e.nickname}
                      </span>
                    ))}
                </td>
              </tr>
              <tr className="table-active">
                <th colSpan="4">Practitioner Information</th>
              </tr>
              <tr>
                <th>Firstname</th>
                <td>
                  <input
                    type="text"
                    defaultValue={firstname}
                    name="firstname"
                    ref={register}
                  />
                </td>
                <th>Lastname</th>
                <td>
                  <input
                    type="text"
                    defaultValue={lastname}
                    name="lastname"
                    ref={register}
                  />
                </td>
              </tr>
              <tr>
                <th>Email</th>
                <td>
                  <input
                    type="text"
                    defaultValue={email}
                    name="email"
                    ref={register}
                  />
                </td>
                <th>Phone</th>
                <td>
                  <p>
                    <label>Phone Country Code</label>
                    <input
                      type="text"
                      defaultValue={phone_country_code}
                      name="phone_country_code"
                      ref={register}
                    />
                  </p>
                  <p>
                    <label>Phone Number</label>
                    <input
                      type="text"
                      defaultValue={phone_number}
                      name="phone_number"
                      ref={register}
                    />
                  </p>
                </td>
              </tr>
              <tr>
                <th>Conventionnement</th>
                <td>{conventionnement}</td>
                <th>Gender</th>
                <td>
                  <select name="gender" defaultValue={gender}
                          ref={register}>
                    <option value="female">Female</option>
                    <option value="male">Male</option>
                  </select>
                </td>
              </tr>
              <tr>
                <th>Profession</th>
                <td>{profession}</td>
                <th>Languages</th>
                <td>
                  <select
                    multiple={true}
                    name="language_ids"
                    defaultValue={language_ids}
                    ref={register}
                  >
                    {practitionerLanguages.map((e) => (
                      <option key={e.id} value={e.id}>
                        {e.name}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
              <tr>
                <th>Specialities</th>
                <td>
                  <select
                    multiple={true}
                    name="practitioner_speciality_ids"
                    defaultValue={practitioner_speciality_ids}
                    ref={register}
                  >
                    {practitionerSpecialities.map((e) => (
                      <option key={e.id} value={e.id}>
                        {e.name}
                      </option>
                    ))}
                  </select>
                </td>
                <th>Non Regulated Profession</th>
                <td>{non_regulated_profession}</td>
              </tr>
              <tr className="table-active">
                <th colSpan="4">Billing Address</th>
              </tr>
              <tr>
                <th colSpan="1">Billing Street</th>
                <td colSpan="3">{billing_street}</td>
              </tr>
              <tr>
                <th>Billing Town</th>
                <td>{billing_town}</td>
                <th>Billing zipcode</th>
                <td>{billing_zipcode}</td>
              </tr>
              <tr className="table-active">
                <th colSpan="4">Medical Information</th>
              </tr>
              <tr>
                <th>Vital card</th>
                <td>{vital_card}</td>
                <th>Tiers payant</th>
                <td>{tiers_payant}</td>
              </tr>
              <tr>
                <th>Cmu</th>
                <td>{cmu}</td>
              </tr>
              <tr className="table-active">
                <th colSpan="4">Offices</th>
              </tr>
              <tr>
                <td colSpan="4">{JSON.stringify(practitioner_office_ids)}</td>
              </tr>
              <tr className="table-active">
                <th colSpan="4">Others</th>
              </tr>
              <tr>
                <th colSpan="4">
                  Time Frame: {time_frame} <br />
                  Apply Limitation : {apply_limitation} <br />
                  LimitedTimeCall : {limitedTimeCall} <br />
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      </React.Fragment>
    </form>
  );
};

class PractitionerEditContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      practitioner: null,
      loading: true,
      practitionerLanguages: [],
      practitionerSpecialities: [],
      subscriptionPlans: [],
    };
  }

  onSave = async (id, payload) => {
    this.setState({
      loading: true,
    });
    const res = await Api.patch(`/practitioner/${id}`, {
      id,
      ...payload,
    });
    this.setState({
      loading: false,
      practitioner: {
        id,
        ...res.data.payload,
      },
    });
    if (res.data.error) {
      SwalAlert.error('Update Error: Phone number exits', () => { });
    } else {
      SwalAlert.success('Update Success', () => { });
    }
  };

  componentDidMount = async () => {
    const { id } = this.props.match.params;
    const res = await Api.get(`/practitioner/${id}`);
    const practitionerLanguages = await Api.getPractitionerLanguages('fr');
    const practitionerSpecialities = await Api.getPractitionerSpecialities(
      'fr',
    );
    const subscriptionPlans = await Api.getSubscriptionPlans();
    this.setState({
      id,
      practitioner: res.data,
      practitionerLanguages,
      practitionerSpecialities,
      subscriptionPlans,
      loading: false,
    });
  };

  render() {
    const {
      id,
      practitioner,
      loading,
      practitionerLanguages,
      practitionerSpecialities,
      subscriptionPlans,
    } = this.state;

    return (
      <React.Fragment>
        {loading ? (
          <Loading />
        ) : (
            <PractititionerEditForm
              onSave={this.onSave}
              practitionerLanguages={practitionerLanguages}
              practitionerSpecialities={practitionerSpecialities}
              subscriptionPlans={subscriptionPlans}
              practitioner={practitioner}
            />
          )}
      </React.Fragment>
    );
  }
}

export default withRouter(PractitionerEditContainer);
