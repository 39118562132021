import React from 'react';
import { Route, Switch } from "react-router-dom";
import {
    DashboardContainer, DetailLogContainer,
    LogContainer, ManageFeatureContainer,
    PlanHomeContainer,
    PractitionerHomeContainer,
    ConfigHomeContainer,
    LoginContainer,
    SubscriptionHomeContainer,
    PartnerContainer,
    SettingContainer,
    LanguageHomeContainer,
    GroupManagementContainer,
    GroupUsersContainer,
    WebhookContainer
} from "../containers";
import AdminAccountHomeContainer from '../containers/admin-account/AdminAccountHomeContainer';



export default function Routes(props) {
    const { openModal, closeModal } = props;
    const containers = [
        {
            path: '/',
            isExactPath: true,
            module: <DashboardContainer
                openModal={openModal}
                closeModal={closeModal}
            />
        },
        {
            path: '/configs',
            module: <ConfigHomeContainer
                openModal={openModal}
                closeModal={closeModal} />
        },
        {
            path: '/practitioners',
            module: <PractitionerHomeContainer
                openModal={openModal}
                closeModal={closeModal}
            />
        },
        {
            path: '/subscriptions',
            module: <SubscriptionHomeContainer
                openModal={openModal}
                closeModal={closeModal}
            />
        },
        {
            path: '/plans',
            module: <PlanHomeContainer
                openModal={openModal}
                closeModal={closeModal}
            />
        },
        {
            path: '/logs',
            module: <LogContainer
                openModal={openModal}
                closeModal={closeModal}
            />
        },
        {
            path: '/log/:id',
            module: <DetailLogContainer
                openModal={openModal}
                closeModal={closeModal}
            />
        },
        {
            path: '/features',
            module: <ManageFeatureContainer
                openModal={openModal}
                closeModal={closeModal}
            />
        },
        {
            path: '/login',
            module: <LoginContainer
                openModal={openModal}
                closeModal={closeModal} />
        },
        {
            path: '/partner',
            module: <PartnerContainer
                openModal={openModal}
                closeModal={closeModal} />
        },
        {
            path: '/setting',
            module: <SettingContainer />
        },
        {
            path: '/languages',
            module: <LanguageHomeContainer
                openModal={openModal}
                closeModal={closeModal} />
        },
        {
            path: '/accounts',
            module: <AdminAccountHomeContainer
                openModal={openModal}
                closeModal={closeModal} />

        },
        {
            path: '/group-management',
            module: <GroupManagementContainer
                openModal={openModal}
                closeModal={closeModal} />
        },
        {
            path: '/webhooks',
            module: <WebhookContainer
                openModal={openModal}
                closeModal={closeModal} />
        },
        {
            path: '/group-users/:id',
            module: <GroupUsersContainer
                openModal={openModal}
                closeModal={closeModal} />
        },


    ]

    return (
        <Switch>
            {
                containers.map((c) => <Route exact={c.isExactPath} key={c.path} path={c.path}>
                    {c.module}
                </Route>)
            }


        </Switch>
    )
}