import swal from "sweetalert";

class SwalAlert {
  static confirm = (title, onConfirm, onCancel = () => { }) => {
    const options = {
      title,
      icon: "warning",
      buttons: ["Cancel", "Confirmed"],
      dangerMode: true,
    };
    swal(options).then((isConfirmed) => {
      if (isConfirmed) {
        onConfirm();
      } else {
        onCancel();
      }
    });
  };

  static success = (text, onClose = () => { }) => {
    const options = {
      text,
      icon: "success",
    };
    swal(options).then(() => {
      onClose();
    });
  };

  static error = (text, onClose = () => { }) => {
    const options = {
      text,
      icon: "error",
    };
    swal(options).then(() => {
      onClose();
    });
  };
}

export default SwalAlert;
