// forms
import { useForm } from 'react-hook-form';
import './style.scss'

const mystyle = {
    container: {
        display: 'flex',
        width: '50%',
        alignItems: 'center',
    },
    lable: {
        margin: '0 10px 0 0',
    },
    inputWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    }
};

const AdminAccountToolbar = (props) => {
    const { register, handleSubmit, watch, errors } = useForm();
    const { onFilterChanges, filter } = props;

    const onSubmit = (data) => {
        onFilterChanges(data);
    };

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
            }}
        >
            <div style={mystyle.container}>
                <div style={mystyle.inputWrapper}>
                    <label className="test_label" style={mystyle.lable} htmlFor="form1">Search</label>
                    <input
                        type="search"
                        id="form1"
                        aria-label="Search"
                        name="s"
                        ref={register}
                        defaultValue={filter.s || ''}
                        placeholder="find account by Username"
                        className="form-control"
                        onChange={handleSubmit(onSubmit)} />
                </div>
            </div>
            <div className="mt-2" style={mystyle.container}>
                <label style={mystyle.lable}>Billing Interval</label>
                <div className="input-field">
                    <select
                        className="form-select"
                        ref={register}
                        onChange={handleSubmit(onSubmit)}
                        aria-label="Status"
                        name="status"
                        defaultValue={filter.status || ''}
                    >
                        <option value="">All</option>
                        <option value="admin">Admin</option>
                        <option value="sys_admin">System Admin</option>
                    </select>
                </div>
            </div>
        </form>
    );
};

export default AdminAccountToolbar;
