import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import Api from '../../api/core/base';
import { Loading } from '../../components';
import { SwalAlert } from '../../helpers';

const TestingSchema = yup.object({
    partnerId: yup.string().required('Partner is required'),
    action: yup.string().required('Action required'),
    payload: yup.string().required('Payload required').test('format', 'Wrong Json format', val => payloadValidate(val)),
});

const payloadValidate = (data) => {
    try {
        let payload = JSON.parse(data);
        let newPayload = JSON.stringify(payload);
        return true
    } catch (error) {
        console.log('Json Format [DEBUG] : ', error)
        return false;
    }
}

const TestingContainer = (props) => {
    const { } = props;
    const [appointmentId, setAppointmentId] = useState("");
    const [select, setSelect] = useState("create");
    const [step, setStep] = useState("input");
    const [inputData, setInputData] = useState({});
    const [loading, setLoading] = useState(false);
    const [signature, setSignature] = useState("");
    const [finishData, setFinishData] = useState({});
    const schema = step === 'input' ? TestingSchema : yup.object({});
    const { register, handleSubmit, errors, trigger } = useForm({
        resolver: yupResolver(schema),
    });

    const generateSignature = async (data) => {
        try {
            setLoading(true)
            data.payload = JSON.parse(data.payload);
            setInputData(data)
            let res
            if (select === 'delete') {
                res = await Api.post(`/get/appointment/${appointmentId}?partner=${data.partnerId}&signature`,
                    data.payload
                );
            } else {
                res = await Api.post(`/get/appointment?partner=${data.partnerId}&signature`, {
                    ...data.payload
                });
            }



            if (!res.data) {
                throw new Error('Can not generate Signature')
            }

            setLoading(false)
            setSignature(res.data)
            setStep("generateSignature")
        } catch (error) {
            setLoading(false)
            console.log("ERROR : ", error.message)
            await SwalAlert.error(error.message, async () => {
            });
        }
    }

    const submitRendezvous = async () => {
        let res
        try {
            setLoading(true)
            if (inputData.action === 'delete') {
                res = await Api.post(`/v1/appointment/${appointmentId}?partner=${inputData.partnerId.trim()}&signature=${signature}`, 
                    inputData.payload
                ).catch((error) => {
                    throw new Error(error.response.data.error)
                })
            } else {
                res = await Api.post(`/v1/appointment?partner=${inputData.partnerId.trim()}&signature=${signature}`, {
                    ...inputData.payload
                }).catch((error) => {
                    throw new Error(error.response.data.error)
                })
            }

            setFinishData(res.data)
            setLoading(false)
            setStep("finish")
        } catch (error) {
            console.log('submitRendezvous ERROR [DEBUG] : ', new Error(error))
            setLoading(false)
            await SwalAlert.error(error.message, async () => {
                props.closeModal()
            });
        }
    }

    const onSubmit = async (data, e) => {
        e.preventDefault();

        try {
            switch (step) {
                case 'input':
                    await generateSignature(data)
                    break;
                case 'generateSignature':
                    await submitRendezvous()
                    break;

                default:
                    props.closeModal();
                    break;
            }
        } catch (error) {

        }
    };

    const onChangeSelect = (e) => {
        setSelect(e.target.value)
    }

    const onChangeAppointmentId = (e) => {
        setAppointmentId(e.target.value)
    }

    return (
        <form className="form-flex" onSubmit={handleSubmit(onSubmit)}>
            <div className="card-body">
                {
                    loading ? <Loading /> : (
                        <div className="row mb-2">
                            {
                                step === 'input' ? (
                                    <>
                                        <div className="col-md-12">
                                            <label htmlFor="partnerId">Partner Id:</label>
                                            <input
                                                type="text"
                                                placeholder="example: capi-91281dee-0045-4bcb-b4e2-6c9cee6e7c38"
                                                name="partnerId"
                                                id="partnerId"
                                                autoFocus={true}
                                                ref={register} />
                                            {errors.partnerId && (
                                                <div className="form-error error mb-1 ml-2" role="alert">
                                                    {errors.partnerId.message}
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-12">
                                            <label htmlFor="action">Action:</label>
                                            <select ref={register} name="action" onChange={onChangeSelect}>
                                                <option value="create">Create</option>
                                                <option value="delete">Delete</option>
                                            </select>
                                        </div>

                                        { select === 'delete' 
                                        ? (
                                            <div className="col-md-12">
                                            <label htmlFor="signature">Appointment Id:</label>
                                            <input
                                                type="text"
                                                autoFocus={false}
                                                onChange={onChangeAppointmentId}
                                                value={inputData.appointmentID} />
                                            </div>
                                        ) 
                                        : ''}
                                        <div className="col-md-12">
                                            <label htmlFor="payload">Payload:</label>
                                            <textarea
                                                type="text"
                                                rows="10"
                                                placeholder='{"name":"abc},"age":"13"}'
                                                name="payload"
                                                id="payload"
                                                autoFocus={true}
                                                ref={register} />
                                            {errors.payload && (
                                                <div className="form-error error mb-1 ml-2" role="alert">
                                                    {errors.payload.message}
                                                </div>
                                            )}
                                        </div>
                                    </>
                                ) : (step === 'generateSignature' ? (
                                    <>
                                        <div className="col-md-12">
                                            <label htmlFor="signature">Signature:</label>
                                            <input
                                                type="text"
                                                readOnly
                                                name="signature"
                                                autoFocus={true}
                                                value={signature} />
                                        </div>
                                        <div className="col-md-12">
                                            <label htmlFor="action">Action:</label>
                                            <input
                                                type="text"
                                                name="action"
                                                readOnly
                                                value={inputData.action}
                                                autoFocus={true} />
                                        </div>
                                    </>
                                ) : (
                                        <>
                                            <div className="col-md-12">
                                                <label htmlFor="signature">Appointment Id:</label>
                                                <input
                                                    type="text"
                                                    readOnly
                                                    autoFocus={true}
                                                    value={finishData.appointmentID} />
                                            </div>
                                            <div className="col-md-12">
                                                <label htmlFor="action">Appointment Link:</label>
                                                <input
                                                    type="text"
                                                    readOnly
                                                    value={finishData.appointmentLink}
                                                    autoFocus={true} />
                                            </div>
                                        </>
                                    )
                                    )
                            }
                        </div>
                    )
                }

                <button
                    type="submit"
                    onClick={() => {
                        trigger();
                    }}
                    title="Save"
                    name="save"
                    disabled={loading}
                    className="btn btn-danger btn-lg float-right">{step === 'finish' ? 'Ok' : 'Submit'}</button>
            </div>
        </form>
    );
}

export default TestingContainer;
