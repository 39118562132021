/* eslint-disable no-useless-constructor */
import React, { Component } from 'react';
import Api from '../../api/core/base';
import { Loading } from '../../components';

class PractitionerViewContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      practitionerLanguages: [],
      practitionerSpecialities: [],
      subscriptionPlans: [],
    };
  }

  componentDidMount = async () => {
    const practitionerLanguages = await Api.getPractitionerLanguages('fr');
    const practitionerSpecialities = await Api.getPractitionerSpecialities(
      'fr',
    );
    const subscriptionPlans = await Api.getSubscriptionPlans();
    this.setState({
      loading: false,
      practitionerLanguages,
      practitionerSpecialities,
      subscriptionPlans,
    });
  };

  render() {
    const { practitioner } = this.props;
    const {
      id,
      firstname,
      lastname,
      email,
      phone_country_code,
      phone_number,
      gender,
      profession,
      userType,
      conventionnement,
      practitioner_speciality_ids,
      language_ids,
      vital_card,
      tiers_payant,
      cmu,
      langId,
      app_id,
      enableTwoFA,
      time_frame,
      apply_limitation,
      limitedTimeCall,
      timezone,
      billing_street,
      billing_town,
      billing_zipcode,
      non_regulated_profession,
      offices,
      practitioner_office_ids,
      customerId,
      current_plan_id,
      isSetUpStripe,
      status,
    } = practitioner;

    const {
      loading,
      practitionerLanguages,
      practitionerSpecialities,
      subscriptionPlans,
    } = this.state;
    if (loading) return <Loading />;
    return (
      <React.Fragment>
        <table className="table table-bordered table-hover mt-5">
          <thead>
            <tr className="table-active">
              <th colSpan="4">Technical Information</th>
            </tr>
            <tr>
              <th>ID</th>
              <td>{id}</td>
              <th>UserType</th>
              <td>{userType}</td>
            </tr>
            <tr>
              <th>AppID</th>
              <td>{app_id}</td>
              <th>LangID</th>
              <td>{langId}</td>
            </tr>
            <tr>
              <th>enableTwoFA?</th>
              <td>
                <input type="checkbox" disabled={true} checked={enableTwoFA} />
              </td>
              <th>Timezone</th>
              <td>{timezone}</td>
            </tr>
            <tr>
              <th>Status</th>
              <td>{status}</td>
              <th>Is Setup Stripe?</th>
              <td>
                <input
                  type="checkbox"
                  disabled={true}
                  checked={isSetUpStripe}
                />
              </td>
            </tr>
          </thead>
          <tbody>
            <tr className="table-active">
              <th colSpan="4">Plans & Subscriptions</th>
            </tr>
            <tr>
              <th>CustomerID</th>
              <td>{customerId}</td>
              <th>Plan</th>
              <td>
                {subscriptionPlans
                  .filter((e) => current_plan_id === e.id)
                  .map((e) => (
                    <span data-planid={e.id} key={e.id}>
                      {e.nickname}
                    </span>
                  ))}
              </td>
            </tr>
            <tr className="table-active">
              <th colSpan="4">Practitioner Information</th>
            </tr>
            <tr>
              <th>Name</th>
              <td>{firstname + ' ' + lastname}</td>
              <th>Email</th>
              <td>{email}</td>
            </tr>
            <tr>
              <th>Phone</th>
              <td>
                {phone_country_code} {phone_number}
              </td>
              <th>Conventionnement</th>
              <td>{conventionnement}</td>
            </tr>
            <tr>
              <th>Gender</th>
              <td>{gender || ''}</td>
              <th>Profession</th>
              <td>{profession}</td>
            </tr>
            <tr>
              <th>Languages</th>
              <td>
                {practitionerLanguages
                  .filter((e) => (language_ids || []).includes(e.id))
                  .map((e) => (
                    <span key={e.id}>{e.name}&nbsp;, </span>
                  ))}
              </td>
              <th>Specialities</th>
              <td>
                {practitionerSpecialities
                  .filter((e) =>
                    (practitioner_speciality_ids || []).includes(
                      e.id,
                    ),
                  )
                  .map((e) => (
                    <span key={e.id}>{e.name}&nbsp;, </span>
                  ))}
              </td>
            </tr>
            <tr>
              <th>Non Regulated Profession</th>
              <td>{non_regulated_profession}</td>
            </tr>
            <tr className="table-active">
              <th colSpan="4">Billing Address</th>
            </tr>
            <tr>
              <th colSpan="1">Billing Street</th>
              <td colSpan="3">{billing_street}</td>
            </tr>
            <tr>
              <th>Billing Town</th>
              <td>{billing_town}</td>
              <th>Billing zipcode</th>
              <td>{billing_zipcode}</td>
            </tr>
            <tr className="table-active">
              <th colSpan="4">Medical Information</th>
            </tr>
            <tr>
              <th>Vital card</th>
              <td>{vital_card}</td>
              <th>Tiers payant</th>
              <td>{tiers_payant}</td>
            </tr>
            <tr>
              <th>Cmu</th>
              <td>{cmu}</td>
            </tr>
            <tr className="table-active">
              <th colSpan="4">Offices</th>
            </tr>
            <tr>
              <td colSpan="4">{JSON.stringify(practitioner_office_ids)}</td>
            </tr>
            <tr className="table-active">
              <th colSpan="4">Others</th>
            </tr>
            <tr>
              <th colSpan="4">
                Time Frame: {time_frame} <br />
                Apply Limitation : {apply_limitation} <br />
                LimitedTimeCall : {limitedTimeCall} <br />
              </th>
            </tr>
          </tbody>
        </table>
      </React.Fragment>
    );
  }
}

export default PractitionerViewContainer;
