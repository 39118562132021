// forms
import { useForm } from 'react-hook-form';

const PractititionerToolbar = (props) => {
  const { register, handleSubmit, watch, errors } = useForm();
  const { onFilterChanges, filter } = props;

  const onSubmit = (data) => {
    onFilterChanges(data);
  };

  return (
    <form
      className="ca-admin-form"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <div className="ca-admin-form__form-field">
        <label>Search</label>
        <div className="input-field">
          <input
            type="text"
            name="s"
            ref={register}
            defaultValue={filter.s || ''}
            onChange={handleSubmit(onSubmit)}
            placeholder="Find practitioner by name or email"
            className="form-control col-6"
          />
        </div>
      </div>
      <div className="ca-admin-form__form-field">
        <label>Status</label>
        <div className="input-field">
          <select
            className="form-select col-3"
            ref={register}
            onChange={handleSubmit(onSubmit)}
            aria-label="Status"
            name="status"
            defaultValue={filter.status || ''}
          >
            <option value="">All</option>
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>
        </div>
      </div>
    </form>
  );
};

export default PractititionerToolbar;
