import React, { Component } from 'react';
import { LinkButton } from '../../components';
import jwt_decode from 'jwt-decode'
import TestingContainer from '../testing/TestingContainer';
import { imagePathFormat } from '../../utils';

class DashboardContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: 'Dashboard',
            links: [
                {
                    name: 'dashboard',
                    permissions: ['admin', 'sys_admin'],
                    link: (
                        <LinkButton key="dashboard" className="setting-option" href="/">
                            <div className="img-container">
                                <img
                                    alt="Dashboard"
                                    src={

                                        imagePathFormat('/images/shared/tachometer-alt-solid.svg')
                                    }
                                />
                            </div>
                            <p>Dashboard </p>
                        </LinkButton>
                    ),
                },
                {
                    name: 'manage-adminAccount',
                    permissions: ['sys_admin'],
                    link: (
                        <LinkButton key="accounts" className="setting-option" href="/accounts">
                            <div className="img-container">
                                <img
                                    style={{ height: 100, width: 100 }}
                                    alt="Manage AdminAccount"
                                    src={imagePathFormat('/images/shared/admin.svg')}
                                />
                            </div>
                            <p>Admin Account</p>
                        </LinkButton>
                    ),
                },
                {
                    name: 'manage-practitioners',
                    permissions: ['admin', 'sys_admin'],
                    link: (
                        <LinkButton key="practitioners" className="setting-option" href="/practitioners">
                            <div className="img-container">
                                <img
                                    alt="Manage Practitioners"
                                    src={imagePathFormat('/images/shared/user-solid.svg')}
                                />
                            </div>
                            <p>Manage Practitioners</p>
                        </LinkButton>
                    ),
                },
                {
                    name: 'manage-configs',
                    permissions: ['admin', 'sys_admin'],
                    link: (
                        <LinkButton key="config" className="setting-option" href="/configs">
                            <div className="img-container">
                                <img
                                    alt="Settings"
                                    src={imagePathFormat('/images/shared/setting.svg')}
                                />
                            </div>
                            <p>Setting config</p>
                        </LinkButton>
                    ),
                },
                {
                    name: 'partner',
                    permissions: ['admin', 'sys_admin'],
                    link: (
                        <LinkButton key="partner" className="setting-option" href="/partner">
                            <div className="img-container">
                                <img src={imagePathFormat("/images/shared/handshake-solid.svg")} alt="" />
                            </div>
                            <p>Partner </p>
                        </LinkButton>
                    )
                },
                {
                    name: 'testing',
                    permissions: ['admin', 'sys_admin'],
                    link: (
                        <LinkButton key="testing" className="setting-option" href="/" onClick={(e) => {
                            this.props.openModal({
                                title: `Partner Api Testing`,
                                size: 'large',
                                disabledClickOutside: true,
                                content: (
                                    <TestingContainer
                                        {...this.props}
                                    />
                                ),
                            });
                        }}>
                            <div className="img-container">
                                <img src={imagePathFormat("/images/shared/cogs-solid.svg")} alt="" />
                            </div>
                            <p>Testing </p>
                        </LinkButton>
                    )
                },
                {
                    name: 'languages',
                    permissions: ['admin', 'sys_admin'],
                    link: (
                        <LinkButton key="languages" className="setting-option" href="/languages">
                            <div className="img-container">
                                <img
                                    alt="Languages"
                                    style={{ width: 80 }}
                                    src={imagePathFormat('/images/shared/globe-solid.svg')}
                                />
                            </div>
                            <p>Languages</p>
                        </LinkButton>
                    ),
                },
                {
                    name: 'setting',
                    permissions: ['admin', 'sys_admin'],
                    link: (
                        <LinkButton key="setting" href="/setting" className="setting-option">
                            <div className="img-container">
                                <img src={imagePathFormat("/images/shared/lock.svg")} alt="" />
                            </div>
                            <p>Password </p>
                        </LinkButton>
                    )
                },
                {
                    name: 'manage-subscriptions',
                    permissions: ['admin', 'sys_admin'],
                    link: (
                        <LinkButton key="subscription" className="setting-option" href="/subscriptions">
                            <div className="img-container">
                                <img
                                    alt="Subscriptions"
                                    src={imagePathFormat('/images/shared/subscription.svg')}
                                />
                            </div>
                            <p>Subscriptions</p>
                        </LinkButton>
                    ),
                },
                {
                    name: 'logs',
                    permissions: ['admin', 'sys_admin'],
                    link: (
                        <LinkButton key="logs" href="/logs" className="setting-option">
                            <div className="img-container">
                                <img src={imagePathFormat("/images/shared/boxes-solid.svg")} alt="" />
                            </div>
                            <p>Logs </p>
                        </LinkButton>
                    )
                },
                {
                    name: 'manage-plan',
                    permissions: ['admin', 'sys_admin'],
                    link: (
                        <LinkButton key="plan" className="setting-option" href="/plans">
                            <div className="img-container">
                                <img
                                    alt="Plans"
                                    src={imagePathFormat('/images/shared/planning.svg')}
                                />
                            </div>
                            <p>Plans</p></LinkButton>
                    ),
                },
                {
                    name: 'Manage feature',
                    permissions: ['admin', 'sys_admin'],
                    link: (
                        <LinkButton key="features" className="setting-option" href="/features">
                            <div className="img-container">
                                <img
                                    alt="Features"
                                    style={{ width: 80 }}
                                    src={imagePathFormat('/images/shared/tasks-solid.svg')}
                                />
                            </div>
                            <p>Manage features</p>
                        </LinkButton>
                    ),
                },
                // {
                //     name: 'dataExport',
                //     permissions: ['admin', 'sys_admin'],
                //     link: (
                //         <LinkButton key="data-expor" href="/data-export" className="setting-option">
                //             <div className="img-container">
                //                 <img src={imagePathFormat("/images/shared/signal-solid.svg")} alt="" />
                //             </div>
                //             <p>Data Export </p>
                //         </LinkButton>
                //     )
                // },
                {
                    name: 'Group Management',
                    permissions: ['admin', 'sys_admin'],
                    link: (
                        <LinkButton key="group" href="/group-management" className="setting-option">
                            <div className="img-container">
                                <img src={imagePathFormat("/images/shared/users-cog-solid.svg")} alt="" />
                            </div>
                            <p>Group Management </p>
                        </LinkButton>
                    )
                }
            ],
        };

    }
    render() {
        const { title, links } = this.state;
        const adminToken = window.localStorage.getItem('ca-app-admin-token') || window.sessionStorage.getItem("ca-app-admin-token");
        let decoded = jwt_decode(adminToken);
        return (
            <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex justify-content-between align-items-center">
                    <h6 className="m-0 font-weight-bold text-primary">{title}</h6>
                </div>
                <div className="card-body setting-options">
                    {links.filter(l => {
                        return l.permissions && l.permissions.includes(decoded.role)
                    }).map((l) => l.link)}

                </div>
            </div>
        );
    }
}
export default DashboardContainer;
