import SwalAlert from './Swal';
import { PAGE_SIZE_DEFAULT } from '../constants';

const isContain = (v, k) => {
  return typeof v === 'string' && !['status'].includes(k);
};

const buildFilterParams = (
  filter = {},
  paginator = { pageIndex: 1, pageSize: PAGE_SIZE_DEFAULT },
  sort = { field: 'id', direction: 'desc' },
) => {
  const filterParams = {};
  // http://localhost:1337/practitioners?filter[][or][0][firstname][contains]=nguyen&filter[][or][1][lastname][contains]=nguyen&filter[][or][0][app_id]=normal&filter[][or][1][app_id]=normal
  if (Object.keys(filter).length > 0) {
    let { or, and } = filter;
    and = and || {};
    // and only
    if (!or) {
      const andFields = Object.keys(and);
      if (andFields.length > 0) {
        andFields.map((k) => {
          if (and[k] && and[k].length > 0) {
            if (isContain(and[k], k)) {
              // if (typeof and[k] === 'string') {
              filterParams[`filter[][${k}][contains]`] = and[k];
            } else {
              filterParams[`filter[][${k}]`] = and[k];
            }
          }
        });
      }
    } else {
      const orFields = Object.keys(or);

      if (orFields.length > 0) {
        orFields.map((k, k_idx) => {
          if (or[k] && (or[k].length > 0 || or[k] > 0)) {
            // or field
            if (isContain(or[k], k)) {
              // if (typeof or[k] === 'string') {
              filterParams[`filter[][or][${k_idx}][${k}][contains]`] = or[k];
            } else {
              filterParams[`filter[][or][${k_idx}][${k}]`] = or[k];
            }
            // and field
            const andFields = Object.keys(and);
            if (andFields.length > 0) {
              andFields.map((and_k) => {
                if (and[and_k]) {
                  if (typeof and[and_k] === 'string') {
                    const nonQueryContainKeys = ['status']
                    const isNotContainQuery = nonQueryContainKeys.includes(and_k);
                    filterParams[`filter[][or][${k_idx}][${and_k}]${isNotContainQuery ? '': '[contains]'}`] =
                      and[and_k];
                  } else {
                    filterParams[`filter[][or][${k_idx}][${and_k}]`] =
                      and[and_k];
                  }
                }
              });
            }
          }
        });
      }
    }
  }
  // done filter query

  // page, size
  filterParams.page = paginator ? paginator.pageIndex : 1;
  filterParams.size = paginator ? paginator.pageSize : PAGE_SIZE_DEFAULT;
  // sort
  if (sort && sort.field && sort.field.length > 0) {
    filterParams[`sort[][${sort.field}]`] = sort.direction;
  }
  return filterParams;
};

export { SwalAlert, buildFilterParams };
