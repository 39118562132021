import React, { Component, PureComponent } from "react";
import  Api  from '../../api/core/base';
import { LinkButton } from "../../components";
import { SwalAlert } from "../../helpers";
import ConfigAddFormContainer from "./ConfigAddFormContainer";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import jwt_decode from "jwt-decode";

// const NOTEDITABLE_FIELD = ['product', ]

class ConfigViewContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      settings: [],
      loading: false,
      updatePayload: {},
      isSysadmin: false,
    };
  }

  componentDidMount = async () => {
    await this.fetchData();
    const adminToken =
      window.localStorage.getItem("ca-app-admin-token") ||
      window.sessionStorage.getItem("ca-app-admin-token");
    let decoded = jwt_decode(adminToken);
    this.setState({
      isSysadmin: decoded.role === "sys_admin",
    });
  };

  fetchData = async () => {
    this.setState({
      loading: true,
    });
    const settings = await Api.getSettingConfig();
    let payload = {};
    settings.forEach((val) => {
      payload[val.id] = val.value;
    });
    this.setState({
      loading: false,
      settings,
      updatePayload: payload,
    });
  };

  updateSetting = async () => {
    try {
      this.setState({
        loading: true,
      });
      await Api.patch(`/setting`, {
        payloads: this.state.updatePayload,
      });
      this.setState({
        loading: false,
      });
      await SwalAlert.success("Update Success", async () => {
        this.setState({ isEdit: false });
        await this.fetchData();
      });
    } catch (error) {
      await SwalAlert.error(`Update Setting Failed: ${error}`, async () => {
        this.setState({ isEdit: false });
        await this.fetchData();
      });
    }
  };

    addNewSetting = () => {
        this.props.openModal({
            title: `Add New Setting`,
            size: 'large',
            content: (
                <ConfigAddFormContainer
                    {...this.props}
                    saveSetting={this.saveNewSetting}
                />
            ),
        });

    }

  onValueChange = (event) => {
    let payload = this.state.updatePayload;
    payload[event.target.ariaLabel] = event.target.value;
    AwesomeDebouncePromise(
      this.setState({
        updatePayload: payload,
      }),
      500
    );
  };
  saveNewSetting = async (payload) => {
    try {
      this.setState({
        loading: true,
      });
      await Api.post(`/setting`, {
        id: payload.key,
        value: payload.value,
      });
      this.setState({
        loading: false,
        isEdit: false,
      });
      await SwalAlert.success("Save Success", async () => {
        await this.fetchData();
      });
    } catch (error) {
      await SwalAlert.error(`Update Setting Failed: ${error}`, async () => {
        await this.fetchData();
      });
    }
  }

  addNewSetting = () => {
    this.props.openModal({
      title: `Add New Setting`,
      content: (
        <ConfigAddFormContainer
          {...this.props}
          saveSetting={this.saveNewSetting}
        />
      ),
    });
  };

  
  render() {
    const { isEdit, settings, isSysadmin } = this.state;
    return (
      <>
        <div className="card-header py-3 d-flex justify-content-between align-items-center">
          <h6 className="m-0 font-weight-bold text-primary">Settings</h6>
          <div className="buttons">
            {!isEdit ? (
              <>
                {/* <LinkButton href={'/dashboard'}>Back</LinkButton> */}
                  <button
                    className={"btn btn-danger ml-1"}
                    title="Edit"
                    onClick={() => {
                      this.setState({ isEdit: true });
                    }}
                  >
                    Edit
                  </button>
              </>
            ) : (
              <>
                <button
                  className={"btn btn-primary ml-1"}
                  onClick={() => {
                    this.setState({ isEdit: false });
                  }}
                >
                  Cancel
                </button>
                <button
                  className={"btn btn-danger ml-1"}
                  title="Save"
                  onClick={() => {
                    this.updateSetting();
                  }}
                >
                  Save
                </button>
              </>
            )}
          </div>
        </div>
        {isSysadmin && (
          <button
            className="btn  btn-primary ml-1 btn-sm d-flex align-items-center justify-content-center align-self-end p-2"
            style={{ margin: "20px 20px 20px 0", width: "fit-content" }}
            onClick={this.addNewSetting}
          >
            Add new Setting config
          </button>
        )}
        <div className="card-body d-flex flex-wrap">
          {settings.map((set, index) => {
            return (
              <div
                className="ca-admin-form__form-field mb-3"
                style={{ minWidth: "50%" }}
              >
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    {set.id}
                  </span>
                  {!isEdit ? (
                    <input
                      type="text"
                      style={{ margin: 0 }}
                      className="form-control"
                      value={set.value}
                      placeholder={set.id}
                      aria-label={set.id}
                      onChange={() => {}}
                      aria-describedby="basic-addon1"
                    />
                  ) : (
                    <input
                      type="text"
                      style={{ margin: 0 }}
                      className="form-control"
                      defaultValue={set.value}
                      placeholder={set.id}
                      aria-label={set.id}
                      onChange={this.onValueChange}
                      aria-describedby="basic-addon1"
                      autoFocus
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  }
}

export default ConfigViewContainer;
