import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { HashRouter, NavLink, Route, Switch, withRouter } from 'react-router-dom';
import Api from '../../api/core/base';
import { LinkButton, Loading } from '../../components';
import { SwalAlert } from '../../helpers';

const PlanViewContainer = (props) => {
    const [plan, setPlan] = useState({})
    const [loading, setLoad] = useState(true)
    const [isEdit, setIsEdit] = useState(false)
    const [selectedFeatures, setSelectedFeatures] = useState([])
    const [isAllowTrialChecked, setAllowTrialCheck] = useState(false);
    const [isVisiblePlan, setIsVisiblePlan] = useState(false);
    const [isFreePlan, setIsFreePlan] = useState(false);
    const { features } = plan
    const { featureList } = props
    const { register, handleSubmit, trigger } = useForm();

    const fetchData = async () => {
        try {
            const { id } = props.match.params;
            const res = await Api.get(`/plan/${id}`, {});
            if (!res || res.status !== 200) {
                throw new Error()
            }
            setPlan(res.data)
            setLoad(false)
        } catch (error) {
            await SwalAlert.error('Get data Failed', async () => {
                console.log('Get data Failed: , ', error)
                setLoad(false)
            });
        }
    }

    const formatMoney = (amount) => {
        return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(amount)
    }

    const onSubmit = async (data, e) => {
        e.preventDefault();
        setLoad(true)
        try {
            const res = await Api.patch(`/plan/${plan.id}`, {
                id: plan.id,
                ...data,
            });
            setLoad(false)
            if (res.data) {
                setIsEdit(false)

                await fetchData()
                SwalAlert.success('Update Success', async () => {
                });
            } else {
                SwalAlert.error('Update Failed', () => {
                    setIsEdit(false)
                });
            }
        } catch (error) {
            SwalAlert.error(`Update Failed: ${error}`, () => {
                setIsEdit(false)
            });
        }

    };

    const handleOnChange = (e) => {
        let value = Array.from(e.target.selectedOptions, option => option.value);
        setSelectedFeatures(value)
    }

    const addNewFeature = (e) => {
        if (selectedFeatures.length > 0) {
            let updateFeatures = features
            selectedFeatures.map(f => updateFeatures.push(f))
            const data = {
                features: updateFeatures
            }
            onSubmit(data, e)
        } else {
            SwalAlert.error(`Much select feature`, () => {
            });
        }
    }

    useEffect(() => {
        fetchData()
    }, []);

    return (
        <form className="form-flex" onSubmit={handleSubmit(onSubmit)}>
            <div className="card-header py-3 d-flex justify-content-between align-items-center">
                <h6 className="m-0 font-weight-bold text-primary">Detail: {plan.nickname}</h6>
                <div className="buttons">
                    {
                        !isEdit ?
                            (
                                <>
                                    <LinkButton href={'/plans'}>Back</LinkButton>
                                    <LinkButton
                                        className={'btn btn-danger ml-1'}
                                        title="Edit"
                                        onClick={() => {
                                            setIsEdit(true)
                                        }}
                                    >
                                        Edit
                                        </LinkButton>
                                </>
                            ) : (
                                <>
                                    <button className={"btn btn-primary ml-1"} onClick={() => {
                                        setIsEdit(false)
                                    }}>Cancel</button>
                                    <button
                                        className={'btn btn-danger ml-1'}
                                        title="Save"
                                        type="submit"
                                        name="save"
                                        onClick={() => {
                                            trigger();
                                        }}
                                    >
                                        Save
                                        </button>
                                </>
                            )
                    }
                </div>
            </div>
            {
                loading ? <Loading /> : (
                    <div className="card-body">
                        <h3>{plan.nickname}</h3>
                        <label className="text-primary d-block font-weight-bold"><input style={{ maxWidth: '20px' }} type="checkbox" disabled={true} checked={true} className="mr-2" />Can be Sold</label>
                        <label className="text-primary d-block font-weight-bold"><input style={{ maxWidth: '20px' }} type="checkbox" disabled={true} checked={true} className="mr-2" />Can be Purchased</label>
                        <label className="text-primary d-block font-weight-bold"><input style={{ maxWidth: '20px' }} type="checkbox" disabled={true} checked={true} className="mr-2" />Can be Subscribed</label>

                        <HashRouter>
                            <nav className="navbar navbar-expand-sm bg-dark navbar-dark mt-4">
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <NavLink to="/info" className="nav-link" >
                                            General Information
                                            </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/features" className="nav-link" activeStyle={{}}>
                                            Features
                                            </NavLink>
                                    </li>
                                </ul>
                            </nav>
                            <Switch>
                                <Route path="/info">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Type</th>
                                                <td style={{ verticalAlign: 'middle' }}>Service</td>
                                                <th>List Price</th>
                                                <td>
                                                    {
                                                        isEdit ? (
                                                            <input
                                                                type="number"
                                                                step="0.001"
                                                                className="p-0 text-center"
                                                                defaultValue={plan.amount}
                                                                name="amount"
                                                                ref={register}
                                                            />
                                                        ) : formatMoney(plan.amount)
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Welcome Plan</th>
                                                <td>
                                                    <input className="col-1" type="checkbox" disabled={true} checked={plan.isWelcomePlan} />
                                                </td>
                                                <th>Free Plan</th>
                                                <td>
                                                    <input className="col-1" type="checkbox" ref={register} name="isFree"  defaultChecked={plan.isFree}  onChange={(e) => {
                                                        setIsFreePlan(e.target.checked)
                                                    }}/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Allow Trial</th>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <input
                                                        type="checkbox"
                                                        className="col-1"
                                                        onChange={(e) => {
                                                            setAllowTrialCheck(e.target.checked)
                                                        }}
                                                        ref={register}
                                                        disabled={!isEdit || plan.isFree}
                                                        name="isAllowTrial"
                                                        defaultChecked={plan.isAllowTrial} />
                                                </td>
                                                <th>Is Visible</th>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <input
                                                        type="checkbox"
                                                        className="col-1"
                                                        onChange={(e) => {
                                                            setIsVisiblePlan(e.target.checked)
                                                        }}
                                                        ref={register}
                                                        name="isVisible"
                                                        defaultChecked={plan.isVisible} />
                                                </td>
                                                <th>Trial Period Days</th>
                                                <td>
                                                    {
                                                        isEdit && (plan.isAllowTrial || isAllowTrialChecked) ? (
                                                            <input
                                                                type="number"
                                                                className="p-0 text-center"
                                                                defaultValue={plan.trial_period_days}
                                                                name="trial_period_days"
                                                                ref={register}
                                                            />
                                                        ) : plan.trial_period_days
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Billing Interval</th>
                                                <td>{plan.interval}</td>
                                                <th>Billing Interval Count</th>
                                                <td>{plan.interval_count}</td>
                                            </tr>
                                            <tr>
                                                <th>Inherit Plan</th>
                                                <td>{plan.isFree ? "" : "PRO_PLAN"}</td>
                                                <th>Base Plan</th>
                                                <td>
                                                    <input className="col-1" type="checkbox" disabled={true} checked={plan.isBasePlan} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Stripe Product ID</th>
                                                <td>{plan.product}</td>
                                                <th>Stripe Plan ID</th>
                                                <td>{plan.price}</td>
                                            </tr>
                                        </thead>

                                    </table>
                                </Route>
                                <Route path='/features'>
                                    {
                                        isEdit && (<div className="mt-2">
                                            <div className="d-flex justify-content-between mb-2">
                                                <h4>Add new Feature</h4>
                                                <button
                                                    className={'btn btn-danger ml-1'}
                                                    title="Add"
                                                    disabled={selectedFeatures.length <= 0}
                                                    onClick={addNewFeature}
                                                    name="add"
                                                >
                                                    Add
                                                    </button>
                                            </div>
                                            <select className="form-select" onChange={handleOnChange} multiple aria-label="multiple select example" size="3">
                                                {
                                                    featureList.filter((feature) => !features.includes(feature))
                                                        .map((feature, index) => <option key={index} value={feature}>{feature}</option>)
                                                }
                                            </select>
                                        </div>

                                        )
                                    }
                                    <table className="table table-bordered table-hover table-striped">
                                        <tbody>
                                            {features
                                                .map((c, c_idx) => (
                                                    <tr key={c_idx}>
                                                        <th colSpan="1" style={{ border: 'none' }}>{c_idx < 1 ? 'Plan Features' : ''}</th>
                                                        <td colSpan="1" style={{ fontSize: 12 }}>{c}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </Route>
                            </Switch>
                        </HashRouter>


                    </div>
                )
            }
        </form>
    );
}

export default withRouter(PlanViewContainer);
