import React from 'react';
import { LinkButton } from '../../components';


const displayColumns = [
    {
        name: 'id',
        displayName: 'ID'
    },
    {
        name: 'username',
        displayName: 'Username',
    },
    {
        name: 'role',
        displayName: 'Role',
    },
    {
        name: 'action',
        displayName: 'Action',
    },
];

const AdminAccountTableView = (props) => {
    const { adminAccounts, onDeletePlan, openEditDialog, onDeleteAccount } = props
    return (
        <>
            {
                adminAccounts && adminAccounts.length > 0 ? (
                    <table className="table table-bordered mt-5">
                        <thead>
                            <tr>
                                {displayColumns.map((c, h_idx) => (
                                    <th
                                        scope="col"
                                        key={h_idx}
                                    >
                                        {c.displayName}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {adminAccounts.map((r, r_idx) => (
                                <tr
                                    key={r_idx}>
                                    {displayColumns
                                        .filter((c) => c.name !== 'action')
                                        .map((c, c_idx) => (
                                            <td key={c_idx}>
                                                { r[c.name]}
                                            </td>
                                        ))
                                    }
                                    <td className="ca-admin-table-actions">
                                        <LinkButton
                                            onClick={() => {
                                                openEditDialog(r)
                                            }}
                                            className="btn btn-primary"
                                            title="View"
                                        >
                                            <i className="fa fa-pen" aria-hidden="true"></i>
                                        </LinkButton>
                                        {
                                            r['role'] !== 'sys_admin' &&
                                            <LinkButton
                                                onClick={(e) => {
                                                    onDeleteAccount(r.id, e)
                                                }}
                                                className="btn btn-danger"
                                                title="Archive"
                                            >
                                                <i className="fa fa-archive" aria-hidden="true"></i>
                                            </LinkButton>
                                        }
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                        <div className="p-3 text-center">
                            <span>No Data</span>
                        </div>
                    )

            }
        </>
    );
}

export default AdminAccountTableView;
