
import React from 'react';
import Api from '../../../api/core/base';
import { SwalAlert } from '../../../helpers';

class CreateFeatureComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
    }

  }

  handlerEventFeature = async () => {
    const { name } = this.state
    const { id } = this.props
    try {
      if (this.props.id) {
        const res = await Api.patch(`/feature/${id}`, { name });
        SwalAlert.success('Update Success', () => {
          this.props.closeModal()
          this.props.onSuccess();
        });
        return res ? res.data : [];
      } else {
        const res = await Api.post('/feature', { name });
        SwalAlert.success('Create Success', () => {
          this.props.closeModal()
          this.props.onSuccess();
        });
        return res ? res.data : [];
      }

    } catch (error) {

    }
  }

  childOnChange = (event) => {
    this.setState({
      name: event.target.value
    })
  }

  render() {
    return <>
      <div className="mb-3">
        <label className="form-label">Name</label>
        <input type="text" className="form-control" onChange={this.childOnChange} />
        <div className="d-flex flex-row-reverse">
          <button type="button" className="btn btn-primary m-2" onClick={this.handlerEventFeature}>Save</button>
          <button type="button" className="btn btn-secondary m-2" onClick={this.props.closeModal}>Cancel</button>
        </div>

      </div>
    </>
  }
}

export default CreateFeatureComponent