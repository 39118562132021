import React, { Component } from 'react';
import jwt_decode from 'jwt-decode'
import TestingContainer from '../../containers/testing/TestingContainer';
import Api from '../../api/core/base';
import { Link } from 'react-router-dom';
import { imagePathFormat } from '../../utils';
class Sidebar extends Component {
    constructor(props) {
        super(props);
    }

    openModalTesting = () => {
        this.props.openModal({
            title: `Partner Api Testing`,
            size: 'large',
            disabledClickOutside: true,
            content: (
                <TestingContainer
                    {...this.props}
                />
            ),
        });
    }

    componentWillUnmount = () => {
        const adminToken = window.localStorage.getItem('ca-app-admin-token') || window.sessionStorage.getItem("ca-app-admin-token");
        if (!adminToken) {
            window.location.href = `${process.env.REACT_APP_BASENAME === '/' ? '' : process.env.REACT_APP_BASENAME}/login`
        }
    }



    render() {
        let settings = [
            {
                name: "Dashboard",
                link: "/",
                action: null,
                imgSrc: "tachometer-alt-solid.svg",
                icon: 'fas fa-fw fa-tachometer-alt',
                role: "admin",
            },
            {
                name: "Manage Practitioners",
                link: "/practitioners",
                action: null,
                imgSrc: "user-solid.svg",
                icon: 'fas fa-user',
                role: "admin",
            },
            {
                name: "Setting Config",
                link: "/configs",
                action: null,
                imgSrc: "cogs-solid.svg",
                icon: 'fas fa-cog',
                role: "admin",
            },
            {
                name: "Partner",
                link: "/partner",
                action: null,
                imgSrc: "handshake-solid.svg",
                icon: 'fas fa-handshake',
                role: "admin",
            },
            {
                name: "Admin Accounts",
                link: "/accounts",
                action: null,
                imgSrc: "user-solid.svg",
                icon: 'fas fa-user',
                role: "sys_admin",
            },
            {
                name: "Testing",
                link: "#",
                action: this.openModalTesting,
                imgSrc: "cogs-solid.svg",
                icon: 'fas fa-cogs',
                role: "admin",
            },
            {
                name: "Languages",
                link: "/languages",
                action: null,
                imgSrc: "globe-solid.svg",
                icon: 'fas fa-globe',
                role: "admin",
            },
            {
                name: "Password",
                link: "/setting",
                action: null,
                imgSrc: "lock.svg",
                icon: 'fas fa-lock',
                role: "admin",
            },
            {
                name: "Subscriptions",
                link: "/subscriptions",
                action: null,
                imgSrc: "subscription.svg",
                icon: 'fas fa-money-check-alt',
                role: "admin",
            },
            {
                name: "Logs",
                link: "/logs",
                action: null,
                imgSrc: "globe-solid.svg",
                icon: 'fas fa-boxes',
                role: "admin",
            },
            {
                name: "Webhooks",
                link: "/webhooks",
                action: null,
                imgSrc: "globe-solid.svg",
                icon: 'fas fa-boxes',
                role: "admin",
            },
            {
                name: "Manage Plan",
                link: "/plans",
                action: null,
                imgSrc: "globe-solid.svg",
                icon: 'fas fa-tasks',
                role: "admin",
            },
            {
                name: "Manage features",
                link: "/features",
                action: null,
                imgSrc: "globe-solid.svg",
                icon: 'fas fa-tasks',
                role: "admin",
            },
            {
                name: "Group Management",
                link: "/group-management",
                action: null,
                imgSrc: "globe-solid.svg",
                icon: 'fas fa-users-cog',
                role: "admin",
            },
        ];
        const adminToken = window.localStorage.getItem('ca-app-admin-token') || window.sessionStorage.getItem("ca-app-admin-token");
        if (adminToken) {
            let decoded = jwt_decode(adminToken);
            if (decoded.role === 'admin') {
                settings = settings.filter(s => s.role === 'admin')
            }
        }

        return (
            <>
                <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
                    id="accordionSidebar">
                    <Link className="sidebar-brand d-flex align-items-center justify-content-center"
                        to="/">
                        <img alt="logo" src={imagePathFormat("/images/shared/fill-2.svg")} />
                        <div className="sidebar-brand-text mx-3">CA ADMIN</div>
                    </Link>

                    <hr className="sidebar-divider my-0" />

                    <div id="accordionSidebarContent">
                        {
                            settings.map((s) => {
                                return (
                                    <li className="nav-item" onClick={s.action} key={s.name}>
                                        <Link className="nav-link" to={s.link || '#'}>
                                            <i className={s.icon} />
                                            <span>{s.name}</span>
                                        </Link>
                                    </li>
                                )
                            })
                        }

                    </div>
                </ul>
            </>
        );
    }
}

export default Sidebar;
