import React from "react";

class TablePagination extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const pageSizes = [10,20,50];
    const { onPageChange, onPageSizeChange, pageSize, pageIndex, page} = this.props;    
    const to = parseInt(pageSize) * parseInt(pageIndex);
    const from = to - pageSize + 1;
    let pager = '';
    if(page){
      const pageIndexes = new Array(page.totalPages).fill(0);
      pager = (
        <ul className="pagination justify-content-center">
          {pageIndexes.map((v, idx) => {
            const pageID = idx + 1;
            if(pageID !== parseInt(pageIndex)){
              return <li key={pageID} className="page-item"><a href="#"
              onClick={
                (evt) => {
                  evt.preventDefault();
                  onPageChange(pageID);
                }
              }
              className="page-link">{pageID}</a></li>
            }
            // else
            return <li key={pageID} className="page-item active">
              <span className="page-link">{pageID}</span>
              <span className="sr-only">(current)</span>
            </li>
          })}
        </ul>
      )
    }
    return (
      <React.Fragment>
        <nav aria-label="Page navigation example" className="mt-5 ca-admin-pagination">
          <div className="ca-admin-pagination__pagination-details">
            <p>
              {page ? <label>Showing from {from} to {Math.min(to, page.totalElements)} of {page.totalElements} rows</label> : ''}
              <select className="form-select" aria-label="Size" name="page_size" 
              onChange={(e) => {onPageSizeChange(e.target.value)}}
              defaultValue={pageSize}>
                {pageSizes.map((s,s_idx) => <option key={s_idx} value={s}>{s}</option>)}
              </select>
            </p>
          </div>
          {pager}
        </nav>
      </React.Fragment>
    )
  }
}

export default TablePagination;