import { useEffect, useState } from 'react';
import api from '../../api/'
import Swal from '../../helpers/Swal'
import jwt_decode from "jwt-decode";

import Auth from '../../api/auth'
import { imagePathFormat } from '../../utils';
import { useHistory } from 'react-router';

export default function LoginContainer(props) {
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const history = useHistory()
    const onInputUserName = (event) => {
        const userName = event.target.value;
        setUserName(userName);
    }
    const onInputPassword = (event) => {
        const password = event.target.value;
        setPassword(password);
    }
    const onSubmitLogin = async (event) => {
        event.preventDefault();
        if (!userName || !password) {
            setErrorMessage("Please fill in all the fields in form")
            Swal.error("Please fill in all the fields in form");
        }
        // const res = await api.post(`${process.env.REACT_APP_API_ENDPOINT}/api/admin/login`, {
        //     username: userName,
        //     password
        // })

        const res = await Auth.login({ username: userName, password })
        console.log(res)
        const { token, error } = res.data;
        if (error) {
            setErrorMessage("Unable to Login");
            Swal.error("Unable To Login");
        }
        if (token) {
            localStorage.setItem("ca-app-admin-token", token)
            sessionStorage.setItem("ca-app-admin-token", token);
            let decoded = jwt_decode(token);
            localStorage.setItem("ca-app-admin-info", JSON.stringify({ userName: decoded.username, role: decoded.role }))
            window.location.href = `${process.env.REACT_APP_BASENAME === '/' ? '' : process.env.REACT_APP_BASENAME}/`
        }

    }

    useEffect(() => {
        const local_token = localStorage.getItem("ca-app-admin-token");
        const session_token = sessionStorage.getItem("ca-app-admin-token");
        if (local_token || session_token) {
            history.push('/')
        }
    }, [])

    return (
        <div className="wrapper fadeInDown">
            <div id="formContent">
                <div className="fadeIn first">
                    <img src={imagePathFormat("/images/shared/logo.svg")} id="icon" alt="User Icon" />
                </div>
                <form id="login_form">
                    <input type="text" id="login" className="mt-3 fadeIn second" name="username" placeholder="login" onInput={(e) => { onInputUserName(e) }} />
                    <input type="password" id="password" className="mt-3 fadeIn third" name="password"
                        placeholder="password" onInput={(e) => { onInputPassword(e) }} />
                    <p className="error"></p>
                    <input type="submit" className="mt-3 fadeIn fourth" onClick={(e) => onSubmitLogin(e)} value="LOGIN" style={{ fontSize: '0.8rem', fontWeight: 600 }} />
                </form>
            </div>
        </div>
    )
}