import React, { useEffect, useState } from 'react';
import BootstrapTable from "react-bootstrap-table-next";
import { AddPartnerModal, EditPartnerModal } from '../../components'
import Swal from '../../helpers/Swal';
import Api from '../../api/core/base';
import { useHistory } from 'react-router-dom'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

const { SearchBar } = Search;



export default function PartnerContainer(props) {
    const history = useHistory();
    const [partners, setPartners] = useState([])
    const [userInfo, setUserInfo] = useState({});
    const tableActions = (cell, row) => {
        return (<div className="right">
            <a className="edit btn btn-primary" onClick={(e) => { editPartner(props, row) }} title="Like">
                <i className="fas fa-pen"></i>
            </a>
            <a className="remove btn btn-danger" onClick={(e) => { deletePartner(props, row) }} title="Remove">
                <i className="fa fa-trash"></i>
            </a>
        </div>
        )
    }
    const tableColumns = [
        { dataField: "id", text: "ID", style: { width: '38%' } },
        { dataField: "name", text: "Name", style: { width: '9%' } },
        { dataField: "secret_key", text: "Secret Key", style: { width: '42%' } },
        { dataField: "button", text: "Actions", style: { width: '12%' }, formatter: tableActions }
    ]
    const expandRow = {

        renderer: row => (
            <div className="card-header p-4">
                <div className="d-flex justify-content-start">
                    <span className="title">ID:</span>
                    {row.id}
                </div>
                <div className="d-flex justify-content-start">
                    <span className="title">Name:</span>
                    {row.name}
                </div>
                <div className="d-flex justify-content-start">
                    <span className=" title">Secret Key:</span>
                    {row.secret_key}
                </div>
                <div className="d-flex justify-content-start">
                    <span className=" title">White list:</span>
                    {row.whitelist_ips}
                </div>
                <div className="d-flex justify-content-start">
                    <span className=" title">Allow urls:</span>
                    {row.allow_urls}
                </div>
            </div>
        ),
        showExpandColumn: true
    }
    useEffect(async () => {
        const res = await Api.getPartners();
        const userInfo = JSON.parse(localStorage.getItem("ca-app-admin-info"));
        setUserInfo(userInfo);
        if (res && res.partners) {
            setPartners(res.partners);
        }
    }, [])
    const addNewPartner = (props, userInfo) => {
        props.openModal({
            title: 'Add New Partner',
            content: <AddPartnerModal userInfo={userInfo} closeModal={props.closeModal}></AddPartnerModal>
        })
    }
    const editPartner = (props, row) => {
        props.openModal({
            title: 'Edit Partner',
            content: <EditPartnerModal partner={row} closeModal={props.closeModal} ></EditPartnerModal>
        })
    }
    const deletePartner = (props, row) => {
        props.closeModal();
        Swal.confirm(`Do you want to delete partner ${row.name}?`, async () => { await onDeletePartner(row.id) }, props.closeModal())
    }
    const onDeletePartner = async (partnerId) => {
        const res = await Api.deletePartner({ id: partnerId });
        if (res) {
            history.go(0);
        }
    }
    return (
        <div id="wrapper">
            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card shadow mb-4">
                                    <div className="card-header py-3 d-flex justify-content-between align-items-center">
                                        <h6 className="m-0 font-weight-bold text-primary">Partner</h6>
                                        <button className="btn btn-primary" onClick={() => { addNewPartner(props, userInfo) }}>Add new
                                            Partner</button>
                                    </div>
                                    <div className="card-body " id="partner">
                                        <ToolkitProvider
                                            keyField="id"
                                            data={partners}
                                            columns={tableColumns}
                                            search
                                        >
                                            {
                                                props => (
                                                    <div>
                                                        <SearchBar {...props.searchProps} style={{ minWidth: 600 }} />
                                                        <hr />
                                                        <BootstrapTable
                                                            {...props.baseProps} expandRow={expandRow} />
                                                    </div>
                                                )
                                            }
                                        </ToolkitProvider>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
