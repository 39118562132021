import React, { Component } from 'react';
import Api from '../../api/core/base';
import { SwalAlert } from '../../helpers';



class LanguageHomeContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            supportedLanguages: []
        }
    }
    componentDidMount = async () => {
        const supportLanguages = await Api.getSupportLanguages();
        this.setState({...this.state, supportedLanguages: supportLanguages})
        localStorage.setItem('ca-support-language', supportLanguages)
    }

    syncAllLanguagesTranslation = async () => {
        this.setState({
            loading: true
        })
        const syncLanguagesPromises =  this.state.supportedLanguages.map((lang) => {
            Api.get(`/languages/sync-app-languages?langId=${lang}`, {})
        })
        await Promise.all(syncLanguagesPromises)
                    .then(async (res) => {
                        await SwalAlert.success('Fetch Languages Finished', async () => {
                            this.setState({
                                loading: false
                            })
                        })
                    })
                    .catch(async (error) => {
                        console.log('[Fetch Language DEBUG] : ', error)
                        await SwalAlert.error('Fetch Languages Failed', async () => {
                            this.setState({
                                loading: false
                            })
                        })
                    })
    }

    render() {
        const { loading, supportedLanguages } = this.state
        return (
            <div id="wrapper">
                {
                    supportedLanguages && supportedLanguages.length > 0
                        ? (
                            <div id="content-wrapper" className="d-flex flex-column">
                                <div className="container-fluid">
                                    <div className="row">

                                        <div className="col">
                                            <div className="card shadow mb-4">
                                                <div className="card-header py-3 d-flex justify-content-between align-items-center">
                                                    <h6 className="m-0 font-weight-bold text-primary">Languages</h6>
                                                    <button className="btn btn-primary" disabled={loading} onClick={async () => {await this.syncAllLanguagesTranslation()}}>
                                                        Sync all languages translation
                                                        <span className="spinner-border spinner-border-sm loading-state ml-2" role="status"
                                                              aria-hidden={!loading} hidden={!loading}></span>
                                                    </button>
                                                </div>
                                                <div className="card-body " id="partner">
                                                    <table className="table table-bordered mt-5">
                                                        <thead>
                                                        <tr>
                                                            <th scope="col">ID</th>
                                                            <th scope="col">Status</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            supportedLanguages.map((l, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{l}</td>
                                                                        <td>Active</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        ) : (
                            <div className="text-center p-2">No Data</div>
                        )
                }
            </div>
        );
    }
}

export default LanguageHomeContainer;
