import {React, useState, useEffect} from "react";
import {useHistory} from 'react-router-dom';
import Api from '../../api/core/base';

function changeFormatToArray(value) {
    console.log('value : ', value)
    value = value + ','
    value = value.split(',')
    value.length -= 1
    return value
}
export default function AddPartnerModal(props) {
    const history = useHistory();
    const {userInfo, closeModal} = props;
    const {userName, role} = userInfo;
    const [name, setName] = useState(userName);
    const [whiteListIps,setWhiteListIps] = useState([]);
    const [allowUrls, setAllowUrls] = useState([]);
    const [errorField, setErrorField] = useState('');
    useEffect(() => {

    }, [])
    const onAddNewPartner = (e) => {
        e.preventDefault();
        console.log('name', name)
        if(!name) {
            console.log('name', name)
            setErrorField("userName");
        }
        if (!isValidUrls(allowUrls)) {
            alert('Please input valid urls');
            return;
        }
        if (!isValidWhiteListIps(whiteListIps)) {
            alert('Please input valid white list ips');
            return;
        }
        Api.createPartner({
            name: name,
            allow_urls: allowUrls,
            whitelist_ips: whiteListIps
        }).then(() => {
            history.go();
        })

    }
    const onInputWhiteListIps = (e) => {
        setWhiteListIps(e.target.value ? changeFormatToArray(e.target.value) : e.target.value);
    }
    const onInputUserName = (e) => {
        const newName = e.target.value;
        setName(newName);
    }
    const onInputUrls = (e) => {
        setAllowUrls(e.target.value ? changeFormatToArray(e.target.value) : e.target.value);
    }
    const isValidUrls = (allowUrls) => {
        let isValid = true;
        const urlPattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i');
        if (allowUrls) {
            for (let i=0; i<allowUrls.length; i++) {
                if (allowUrls[i] !== '' && !urlPattern.test(allowUrls[i])) {
                    isValid = false
                    break
                }
            }
        }
        return isValid;
    }

    const isValidWhiteListIps = (whiteListIps) => {
        let isValid = true;
        const ipsPattern = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
        if (whiteListIps) {
            for (let i=0; i<whiteListIps.length; i++) {
                if (whiteListIps[i] !== '' && !ipsPattern.test(whiteListIps[i])) {
                    isValid = false
                    break
                }
            }
        }
        return isValid;
    }
    return (<form id="admin-form" className={`need-validation ${errorField ? 'was-validated' : ""}`}>
            <div className="row">
                <div className="col-md-12 input-group has-validation">
                    <label>Username:</label>
                    <input required type="text" className="form-control" value={name ? name : ""
                    } placeholder="Username" name="userName" onInput={(e) => {onInputUserName(e)}}/>
                    <div className="invalid-feedback">
                        Please input user name.
                    </div>
                </div>
                <div className="col-md-12">
                    <label>Allow URLs:</label>
                    <input type="text" className="form-control" placeholder="https://abc.com, https://cde.com" name="allow_urls" onInput={(e) => {onInputUrls(e)}}/>
                    <div className="invalid-feedback">
                        Please input valid urls
                    </div>
                </div>
                <div className="col-md-12">
                    <label >White list:</label>
                    <input type="text" placeholder="1.6.8.0, 1.2.3.4" name="whitelist_ips" onInput={(e) => {onInputWhiteListIps(e)}}/>
                </div>
                <div className="col-md-12 buttons d-flex justify-content-between mb-4 pt-4">
                    <button className="btn btn-primary" type="submit" form="admin-form" onClick={(e) => {onAddNewPartner(e)}}>Submit</button>
                    <button className="btn btn-secondary" onClick={closeModal}>Close</button>
                </div>
            </div>
        </form>
    )
}