import { Link } from 'react-router-dom';

const LinkButton = (props) => {
  const { className, href, children, ...others } = props;
  return (
    <Link {...others} className={className || 'btn btn-primary'} to={href || '#'}>
      {children || 'Undefined'}
    </Link>
  );
};

export default LinkButton;