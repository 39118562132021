import moment from 'moment';
import React from 'react';
import { LinkButton } from '../../components';

const STATUS_COLOR = {
    'paid': 'badge-success',
    'cancel': 'badge-danger',
}


const displayColumns = [
    {
        name: 'nickname',
        displayName: 'Name'
    },
    {
        name: 'interval',
        displayName: 'Billing Interval',
    },
    {
        name: 'interval_count',
        displayName: 'Billing Interval Count',
    },
    {
        name: 'amount',
        displayName: 'List Price',
    },
    {
        name: 'action',
        displayName: 'action',
    },
];

const PlanTableView = (props) => {


    const format_data = (name, value) => {
        let format_value
        switch (name) {
            case 'current_period_start':
            case 'current_period_end':
            case 'date_order':
                format_value = moment.unix(value).format("DD/MM/YYYY HH:MM:SS");
                break;
            case 'practitioner':
                format_value = `${value.firstname || ''} ${value.lastname || ''}`
                break;

            case 'state':
                format_value = <span className={`badge  ${STATUS_COLOR[value]} text-white`}>{value}</span>
                break;

            default:
                format_value = value
                break;
        }

        return format_value
    }
    const { plans, onDeletePlan, usedPlan } = props

    return (
        <>
            {
                plans && plans.length > 0 ? (
                    <table className="table table-bordered mt-5">
                        <thead>
                            <tr>
                                {displayColumns.map((c, h_idx) => (
                                    <th
                                        scope="col"
                                        key={h_idx}
                                    >
                                        {c.displayName}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {plans.map((r, r_idx) => (
                                <tr
                                    key={r_idx}>
                                    {displayColumns
                                        .filter((c) => c.name !== 'action')
                                        .map((c, c_idx) => (
                                            <td key={c_idx}>
                                                {format_data(c.name, r[c.name])}
                                            </td>
                                        ))
                                    }
                                    <td className="ca-admin-table-actions">
                                        <LinkButton
                                            href={`/plans/${r.id}#/info`}
                                            className="btn btn-primary"
                                            title="View"
                                        >
                                            <i className="fa fa-eye" aria-hidden="true"></i>
                                        </LinkButton>
                                        {
                                            (!usedPlan.includes(r.id) && !r.isFree) && (
                                                <LinkButton
                                                    onClick={(e) => {
                                                        onDeletePlan(r.id, e)
                                                    }}
                                                    className="btn btn-danger"
                                                    title="Archive"
                                                >
                                                    <i className="fa fa-archive" aria-hidden="true"></i>
                                                </LinkButton>
                                            )
                                        }
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                        <div className="p-3 text-center">
                            <span>No Data</span>
                        </div>
                    )

            }
        </>
    );
}

export default PlanTableView;
