// forms
import { useForm } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import api from '../../api';
import { buildFilterParams, SwalAlert } from '../../helpers';

const myStyle = {
  container: {
    display: 'flex',
    width: '50%',
    alignItems: 'center',
  },
  label: {
    margin: '0 10px 0 0',
    minWidth: '5rem'
  },
  selectField: {
    minWidth: '10rem'
  },
};

const SubscriptionToolbar = (props) => {
  const [practitioners, setPractitioners] = useState([]);
  const [isRerender, setIsRerender] = useState(false);
  const fetchPractitioners = async (inputValue) => {
    try {
      const params = buildFilterParams(filterBuild(inputValue), { pageIndex: 1, pageSize: 100 }, { status: 'ASC' });
      const res = await api.get('/practitioners', { params });
      if (!res) {
        throw new Error()
      }
      let practitionerOptions = res.data.practitioners.map((prac) => {
        return {
          label: `${prac.firstname} ${prac.lastname}`,
          value: prac.id
        }
      })
      practitionerOptions.unshift({ label: 'All', value: '' })
      setPractitioners(practitionerOptions)
    } catch (error) {
      await SwalAlert.error('Get Practitioner Failed');
    }
  }

  const filterBuild = (filter) => {
    const filterParams = {};
    if (!filter) return filterParams;
    if (filter) {
      // email,firstname,lastname
      filterParams['or'] = {
        firstname: filter,
        lastname: filter,
      };
    }
    return filterParams;
  };




  useEffect(() => {
    fetchPractitioners();
  }, [isRerender]);

  return (
      <div>
        <div style={myStyle.container}>
          <div className='d-flex align-items-center mb-2'>
            <label style={myStyle.label} htmlFor="form1">Practitioner</label>
            <div className="" style={myStyle.selectField}>
              <select
                  className="custom-select"
                  onChange={props.onPractitionerChange}
              >
                {practitioners.map((p) => {
                  return (<option selected={p.value.toString() === props.selectedPractitionerId} value={p.value}>{p.label}</option>)
                })}
              </select>
            </div>
          </div>
        </div>
        <div style={myStyle.container}>
          <label style={myStyle.label} htmlFor="status-group">Status</label>
          <div className="" style={myStyle.selectField}>
            <select
                className="custom-select"
                onChange={props.onStatusChange}
            >
              {[{label: 'All', value: ''}, {label: 'Paid', value: 'paid'}, {label: 'Cancel', value: 'cancel'}].map((s) => {
                return (<option selected={s.value === props.selectedStatus} value={s.value}>{s.label}</option>)
              })}
            </select>
          </div>
        </div>
      </div>
  );
};

export default SubscriptionToolbar;
