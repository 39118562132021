import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, startWith } from 'rxjs/operators';
import Api from '../../api/core/base'
import { LinkButton, Loading, TablePagination } from '../../components';
import { PAGE_SIZE_DEFAULT } from '../../constants';
import { buildFilterParams, SwalAlert } from '../../helpers';
import AdminAccountDialogContainer from './AdminAccountDialogContainer';
import AdminAccountTableView from './AdminAccountTableView';
import AdminAccountToolbar from './AdminAccountToolbar';


class AdminAccountListContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            page: null,
            pageIndex: 1,
            filter: {},
            pageSize: PAGE_SIZE_DEFAULT,
            adminAccounts: [],
        }
    }
    componentDidMount = async () => {
        // request API
        await this.fetchAccounts()
    }

    fetchAccounts = async () => {
        try {
            this.setState({ loading: true });
            // setup

            this.filterSubject = new BehaviorSubject(null);
            this.querySubject = new BehaviorSubject(null);

            this.filterObs = this.filterSubject
                .asObservable()
                .pipe(debounceTime(500))
                .subscribe((filter) => {
                    if (filter) {
                        this.setState({ filter });
                        this.querySubject.next({ filter });
                    }
                });

            this.queryObs = this.querySubject
                .asObservable()
                .pipe(
                    startWith({ filter: {}, pageIndex: 1, pageSize: PAGE_SIZE_DEFAULT }),
                )
                .subscribe(async (query) => {
                    if (query) {
                        // update pageSize or filter
                        if (query.pageSize || query.filter) {
                            // reset page index
                            query.pageIndex = 1;
                        } else {
                            // default
                            query.pageIndex = query.pageIndex || this.state.pageIndex;
                        }
                        query.filter = query.filter
                            ? this.state.filter
                            : { ...this.state.filter, ...query.filter };
                        query.pageSize = query.pageSize || this.state.pageSize;

                        // update state before calling API
                        this.setState({
                            loading: true,
                            filter: query.filter,
                            pageSize: query.pageSize,
                            pageIndex: query.pageIndex,
                        });



                        const newQuery = {
                            filter: this.filterBuild(query.filter),
                            pageIndex: query.pageIndex,
                            pageSize: query.pageSize,
                            sort: { status: 'ASC' },
                        };
                        let { adminaccounts, page } = await this.fetchData(
                            newQuery,
                        );

                        this.setState({
                            loading: false,
                            adminAccounts: adminaccounts,
                            page,
                        });
                    }
                });
        } catch (error) {
            console.error('Failed', error);
            this.setState({ loading: false });
        }
    }

    componentWillUnmount() {
        if (this.filterObs) {
            this.filterObs.unsubscribe();
        }
        if (this.queryObs) {
            this.queryObs.unsubscribe();
        }
    }

    onFilterChanges = (filter) => {
        this.filterSubject.next(filter);
    };

    onPageChange = (pageIndex) => {
        this.querySubject.next({ pageIndex });
    };

    onPageSizeChange = (pageSize) => {
        this.querySubject.next({ pageSize: pageSize });
    };


    fetchData = async ({ filter, pageIndex, pageSize, sort }) => {

        const history = this.props.history
        try {
            const params = buildFilterParams(filter, { pageIndex, pageSize }, sort);
            const res = await Api.get('/api/admin', { params });
            if (res.data.error) {
                if (res.data.error.includes("Access Denied")) {
                    await SwalAlert.error('Access Denied', async () => {
                        history.push('/')
                    });
                    return {}
                }
                throw new Error(res.error)
            }
            return res.data.admins

        } catch (error) {
            console.log('get Admin Data [debug] : ', error)
            await SwalAlert.error('Get data Failed', async () => {
                this.setState({ loading: false })
            });
            return {}
        }
    }

    filterBuild = (filter) => {
        const filterParams = {};
        if (!filter) return filterParams;
        const { s, status } = filter;
        if (s && s.length > 0) {
            filterParams['or'] = {
                username: s,
            };
        }
        if (status) {
            filterParams['and'] = {
                role: status,
            };
        }
        return filterParams;
    };

    onDeleteAccount = async (id, e) => {
        e.preventDefault();
        this.setState({ loading: true })
        try {
            const res = await Api.delete(`/adminaccount/${id}`, {});

            if (!res.data) {
                throw new Error()
            }

            this.setState({ loading: false })
            await SwalAlert.success('Delete Success', async () => {
                await this.fetchAccounts()
            });
        } catch (error) {
            console.log('[DELETE FAILED : ] ', error)
            await SwalAlert.error('Delete Failed ', async () => {
                this.setState({ loading: false })
            });
        }
    }

    onSave = async (data) => {
        try {
            let res
            if (data.id) {
                res = await Api.patch(`/adminaccount/${data.id}`, {
                    ...data
                });
            } else {
                delete data.id
                res = await Api.post(`/adminaccount`, {
                    ...data
                });
            }

            if (!res.data) {
                throw new Error()
            }

            SwalAlert.success('Save Success', async () => {
                this.props.closeModal()
                await this.fetchAccounts()
            });

        } catch (error) {
            console.log('[DEBUG ADD PLAN ERROR] ', error)
            SwalAlert.error('Save Failed', () => {
            });
        }
    }

    openAddDialog = () => {
        this.props.openModal({
            title: `Add new Account`,
            size: 'large',
            content: (
                <AdminAccountDialogContainer
                    {...this.props}
                    type="add"
                    onSave={this.onSave}
                />
            ),
        });
    }

    openEditDialog = (account) => {
        this.props.openModal({
            title: `Edit ${account.username} Account`,
            size: 'large',
            content: (
                <AdminAccountDialogContainer
                    {...this.props}
                    type="edit"
                    account={account}
                    onSave={this.onSave}
                />
            ),
        });
    }


    render() {
        const {
            page,
            filter,
            pageIndex,
            pageSize,
            adminAccounts,
            loading,
            searchValue,
            usedPlan,
        } = this.state
        const { onPageSizeChange, onPageChange, onFilterChanges, onDeletePlan, openEditDialog, openAddDialog, onDeleteAccount } = this
        return (
            <>
                <div className="card-header py-3 d-flex justify-content-between align-items-center">
                    <h6 className="m-0 font-weight-bold text-primary">Admin Account</h6>
                    <div className="buttons">
                        <button
                            className="btn btn-danger mr-2"
                            onClick={openAddDialog}
                        >Add New Admin</button>
                    </div>
                </div>
                {
                    loading ? <Loading />
                        : (
                            <div className="card-body">
                                <AdminAccountToolbar
                                    filter={filter}
                                    searchValue={searchValue}
                                    onFilterChanges={onFilterChanges} />
                                <AdminAccountTableView
                                    openEditDialog={openEditDialog}
                                    onDeleteAccount={onDeleteAccount}
                                    adminAccounts={adminAccounts}
                                    onDeletePlan={onDeletePlan}
                                    usedPlan={usedPlan} />
                                {
                                    adminAccounts && adminAccounts.length > 0 &&
                                    <TablePagination
                                        onPageSizeChange={onPageSizeChange}
                                        onPageChange={onPageChange}
                                        pageIndex={pageIndex}
                                        pageSize={pageSize}
                                        page={page}
                                    />
                                }
                            </div>
                        )
                }
            </>
        );
    }
}

export default withRouter(AdminAccountListContainer);
